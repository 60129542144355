// eslint-disable-next-line no-shadow
export enum AnswerEnum {
  YES = 'YES',
  PARTIAL = 'PARTIAL',
  NO = 'NO',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum AnswerEnumExtended {
  PARTIAL_25 = 'PARTIAL_25',
  PARTIAL_50 = 'PARTIAL_50',
  PARTIAL_75 = 'PARTIAL_75',
  PARTIAL_90 = 'PARTIAL_90',
}

// BNB answers mapping
export enum BnbAnswerEnum {
  YES_DOCUMENTED_3 = 'Yes - documented, w/measured metrics & trending improvement',
  YES_DOCUMENTED_2 = 'Yes - documented & measured w/metrics',
  YES_DOCUMENTED_1 = 'Yes - documented',
  YES_NOT_DOCUMENTED = 'Yes - not documented',
  PARTIAL = 'Partial',
  NO = 'No',
}
// For Mandatory Artifact ALL Settings don't mandate artifact for these answer Options.
export const NOT_MANDATORY_ARTIFACT = ['No', 'Not Applicable', 'Not Tested', 'Not in Place'];

// The comment and artifact boolean values are used In case of there is no default values in framework csv file.
export const FRAMEWORKS_ANSWERS = {
  'HECVAT_4.0': {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    NO: {
      name: 'No',
      score: 1,
      comment: false,
      artifact: false,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
  },
  'PCI_DSS_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    YES_WITH_CUSTOMIZED_APPROACH: {
      name: 'In Place with Customized Approach',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 3,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_TESTED: {
      name: 'Not Tested',
      score: 998,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 6,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 7,
    },
  },
  'PCI_SAQ_D_MER_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_TESTED: {
      name: 'Not Tested',
      score: 998,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 6,
    },
  },
  'PCI_SAQ_D_SP_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_TESTED: {
      name: 'Not Tested',
      score: 998,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 7,
    },
  },
  'PCI_SAQ_P2PE_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_A_EP_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_A_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_B_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_B_IP_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_C_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_C_VT_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_SPOC_4.0.1': {
    YES_IN_PLACE: {
      name: 'In Place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'In Place with CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE_LEGAL_EXCEPTION: {
      name: 'Not in Place with Legal Exception',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  ESSENTIAL_EIGHT_ML1: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    YES_WITH_COMPENSATING_CONTROL: {
      name: 'Yes - with compensating control',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    PARTIAL: {
      name: 'Partial',
      extended: {
        PARTIAL_25: {
          name: 'PARTIAL_25',
          score: 2.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_50: {
          name: 'PARTIAL_50',
          score: 5,
          comment: false,
          artifact: false,
        },
        PARTIAL_75: {
          name: 'PARTIAL_75',
          score: 7.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_90: {
          name: 'PARTIAL_90',
          score: 9,
          comment: false,
          artifact: false,
        },
      },
      questionOptionOrder: 3,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  ESSENTIAL_EIGHT_ML2: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    YES_WITH_COMPENSATING_CONTROL: {
      name: 'Yes - with compensating control',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    PARTIAL: {
      name: 'Partial',
      extended: {
        PARTIAL_25: {
          name: 'PARTIAL_25',
          score: 2.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_50: {
          name: 'PARTIAL_50',
          score: 5,
          comment: false,
          artifact: false,
        },
        PARTIAL_75: {
          name: 'PARTIAL_75',
          score: 7.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_90: {
          name: 'PARTIAL_90',
          score: 9,
          comment: false,
          artifact: false,
        },
      },
      questionOptionOrder: 3,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  ESSENTIAL_EIGHT_ML3: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    YES_WITH_COMPENSATING_CONTROL: {
      name: 'Yes - with compensating control',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    PARTIAL: {
      name: 'Partial',
      extended: {
        PARTIAL_25: {
          name: 'PARTIAL_25',
          score: 2.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_50: {
          name: 'PARTIAL_50',
          score: 5,
          comment: false,
          artifact: false,
        },
        PARTIAL_75: {
          name: 'PARTIAL_75',
          score: 7.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_90: {
          name: 'PARTIAL_90',
          score: 9,
          comment: false,
          artifact: false,
        },
      },
      questionOptionOrder: 3,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  ESSENTIAL_EIGHT_ML1_COMPLIANCE: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    YES_WITH_COMPENSATING_CONTROL: {
      name: 'Yes - with compensating control',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    PARTIAL: {
      name: 'Partial',
      extended: {
        PARTIAL_25: {
          name: 'PARTIAL_25',
          score: 2.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_50: {
          name: 'PARTIAL_50',
          score: 5,
          comment: false,
          artifact: false,
        },
        PARTIAL_75: {
          name: 'PARTIAL_75',
          score: 7.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_90: {
          name: 'PARTIAL_90',
          score: 9,
          comment: false,
          artifact: false,
        },
      },
      questionOptionOrder: 3,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  ESSENTIAL_EIGHT_ML2_COMPLIANCE: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    YES_WITH_COMPENSATING_CONTROL: {
      name: 'Yes - with compensating control',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    PARTIAL: {
      name: 'Partial',
      extended: {
        PARTIAL_25: {
          name: 'PARTIAL_25',
          score: 2.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_50: {
          name: 'PARTIAL_50',
          score: 5,
          comment: false,
          artifact: false,
        },
        PARTIAL_75: {
          name: 'PARTIAL_75',
          score: 7.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_90: {
          name: 'PARTIAL_90',
          score: 9,
          comment: false,
          artifact: false,
        },
      },
      questionOptionOrder: 3,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  ESSENTIAL_EIGHT_ML3_COMPLIANCE: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    YES_WITH_COMPENSATING_CONTROL: {
      name: 'Yes - with compensating control',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    PARTIAL: {
      name: 'Partial',
      extended: {
        PARTIAL_25: {
          name: 'PARTIAL_25',
          score: 2.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_50: {
          name: 'PARTIAL_50',
          score: 5,
          comment: false,
          artifact: false,
        },
        PARTIAL_75: {
          name: 'PARTIAL_75',
          score: 7.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_90: {
          name: 'PARTIAL_90',
          score: 9,
          comment: false,
          artifact: false,
        },
      },
      questionOptionOrder: 3,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  CMMC_LEVEL_1_V2: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
  },
  CMMC_LEVEL_2_V2: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
  },
  'CMMC_LEVEL_3_V2.13': {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
  },
  'PCI_DSS_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_TESTED: {
      name: 'Not Tested',
      score: 998,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_D_MER_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_TESTED: {
      name: 'Not Tested',
      score: 998,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_D_SP_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: true,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: true,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_TESTED: {
      name: 'Not Tested',
      score: 998,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 5,
    },
  },
  'PCI_SAQ_P2PE_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  'PCI_SAQ_A_EP_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  'PCI_SAQ_A_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  'PCI_SAQ_B_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  'PCI_SAQ_B_IP_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  'PCI_SAQ_C_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  'PCI_SAQ_C_VT_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  'PCI_SAQ_SPOC_4.0': {
    YES_IN_PLACE: {
      name: 'Yes - In place',
      score: 10,
      comment: false,
      artifact: false,
      questionOptionOrder: 1,
    },
    YES_WITH_CCW: {
      name: 'Yes - With CCW',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 2,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_IN_PLACE: {
      name: 'Not in Place',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  BNB_FRAMEWORKS: {
    YES_DOCUMENTED_3: {
      name: 'Yes - documented, w/measured metrics & trending improvement',
      score: 10,
      comment: false,
      artifact: false,
    },
    YES_DOCUMENTED_2: {
      name: 'Yes - documented & measured w/metric',
      score: 8,
      comment: false,
      artifact: false,
    },
    YES_DOCUMENTED_1: {
      name: 'Yes - documented',
      score: 6,
      comment: false,
      artifact: false,
    },
    YES_NOT_DOCUMENTED: {
      name: 'Yes - not documented',
      score: 4,
      comment: false,
      artifact: false,
    },
    PARTIAL: {
      name: 'Partial',
      score: 2,
      comment: false,
      artifact: false,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
    },
  },
  BEECHER_MIDMARKET_FRAMEWORKS: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    NO: {
      name: 'No',
      score: 1,
      comment: false,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 0,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
  OTHERS_FRAMEWORKS: {
    YES: {
      name: 'Yes',
      score: 10,
      comment: false,
      artifact: true,
      questionOptionOrder: 1,
    },
    PARTIAL: {
      name: 'Partial',
      extended: {
        PARTIAL_25: {
          name: 'PARTIAL_25',
          score: 2.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_50: {
          name: 'PARTIAL_50',
          score: 5,
          comment: false,
          artifact: false,
        },
        PARTIAL_75: {
          name: 'PARTIAL_75',
          score: 7.5,
          comment: false,
          artifact: false,
        },
        PARTIAL_90: {
          name: 'PARTIAL_90',
          score: 9,
          comment: false,
          artifact: false,
        },
      },
      questionOptionOrder: 2,
    },
    NO: {
      name: 'No',
      score: 0,
      comment: false,
      artifact: false,
      questionOptionOrder: 3,
    },
    NOT_APPLICABLE: {
      name: 'Not Applicable',
      score: 999,
      comment: true,
      artifact: false,
      questionOptionOrder: 4,
    },
  },
};
