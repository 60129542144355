import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../utils.service';
import { GetUserQuery } from '../../API.service';
import { GetEntityQueryExtended, EntityService } from '../entity.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '../file.service';
import { NGXLogger } from 'ngx-logger';
import { AddClientModalComponent } from './add-client-modal/add-client-modal.component';
import { DeleteModalComponent } from 'app/shared/delete-modal/delete-modal.component';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import * as uuid from 'uuid';
import { AddNewClientModalComponent } from './add-client-modal-new/add-new-client-modal.component';
import { AddEntityModalComponent } from './add-entity-modal/add-entity-modal.component';
import { UserService } from '../user.service';
import { AppLevelRoleEnum } from '../enums/appLevelRoles.enum';
import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import { environment } from 'environments/environment';
import { CUSTOMAPIService } from 'app/custom-api.service';

@Component({
  selector: 'cygov-ui-tools',
  templateUrl: './ui-tools.component.html',
  styleUrls: ['./ui-tools.component.scss'],
})
export class UiToolsComponent implements OnInit, OnChanges {
  @Input() inputCollection: any;
  @Input() enableDelete: boolean = false;
  @Input() entitySelected: string = '';
  @Input() serverCallEnable: boolean = false;
  @Input() averageScore;
  @Input() showAvgBox: boolean = false;
  @Input() queryText: string;
  @Input() multiEntityMode: boolean;
  @Input() groupViewMode: boolean;
  @Input() defaultSortBy: string = '';
  @Input() isEditMode: boolean = false;
  @Input() isFromManagement: boolean = false;
  @Output() onViewChanged = new EventEmitter<boolean>();
  @Output() onSearchStarted = new EventEmitter<boolean>(false);
  @Output() onSearchChanged = new EventEmitter<GetEntityQueryExtended[]>();
  @Output() onSortChanged = new EventEmitter<GetEntityQueryExtended[]>();
  @Output() onFilterChanged = new EventEmitter<GetEntityQueryExtended[]>();
  @Output() deleteEntityTrigger = new EventEmitter<boolean>();
  @Output() editModeTrigger = new EventEmitter<boolean>();
  // use this to get search data if you want custom server search or query feature
  @Output() onSearchTextChanged = new EventEmitter<string>();
  queryTextfromInput: string = null;
  sortBy: string = null;
  lastFilterByValue: string = null;
  prevSortBy: string = null;
  sortOptions: string[] = ['name', 'score', 'target'];
  filterBy: string = null;
  filterOptions: string[] = ['low', 'medium', 'high'];
  vendorListFilterOptionsForImpact = [
    { id: 1, name: 'Critical', groupType: 'Impact' },
    { id: 2, name: 'High', groupType: 'Impact' },
    { id: 3, name: 'Medium', groupType: 'Impact' },
    { id: 4, name: 'Low', groupType: 'Impact' },
  ];

  vendorListFilterOptionsForStatus = [
    { id: 5, name: 'Approved', groupType: 'Status' },
    { id: 6, name: 'Pending', groupType: 'Status' },
    { id: 7, name: 'In_Process', groupType: 'Status' },
    { id: 8, name: 'Denied', groupType: 'Status' },
  ];

  selectedVendorListFilters: number[] = [];
  selectedVendorImpactValue: number;
  selectedVendorStatusValue: number;
  selectedVendorFilterValue: number;
  cardView = true;
  cachedCollection: any;
  displayCollection: any;
  showAddEntity = false;
  showAddEntityNew = false;
  showAddMPL = false;
  showDeleteEntity = false;
  isCrbAdjustments = false;
  showLayoutBtns = true;
  isCollection = false;
  isAdmin = false;
  searchDelay: ReturnType<typeof setTimeout>; // search delay is a settimeout which will be initiated on typing
  ngbModalDeleteFrameworkOptions: NgbModalOptions = {
    size: 'sm',
    windowClass: 'delete-entity-modal delete-collection-framework-modal',
    backdropClass: 'delete-entity-backdrop-modal delete-collection-framework-backdrop-modal',
    backdrop: 'static',
  };
  ngbModalUploadOptions: NgbModalOptions = {
    size: 'sm',
    windowClass: 'upload-multi',
    backdropClass: 'upload-multi-back',
    backdrop: 'static',
  };
  uploadFileModalReference: NgbModalRef;
  filter: any;
  sorter: any;
  currentUser: GetUserQuery;
  isMultiEntityRoute = false;
  isGroupView: boolean = false;

  constructor(
    private modalService: NgbModal,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private router: Router,
    private fileService: FileService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private userService: UserService,
    private customApiService: CUSTOMAPIService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputCollection && changes.inputCollection.currentValue) {
      // update cachedCollection and change the inputCollection reference
      this.displayCollection = [...changes.inputCollection.currentValue];
      // updating cachedCollection. As, it is using for filtering, it should be updated.
      this.cachedCollection = [...changes.inputCollection.currentValue];
      this.overallSearchandFilters();
      if (this.defaultSortBy && this.displayCollection && this.displayCollection?.length) {
        this.sortChanged(this.defaultSortBy);
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.showLayoutBtns = !this.router.url.includes('third-party');
    this.isCollection = this.router.url.includes('collection');
    this.isMultiEntityRoute = this.router.url.includes('multi-entity');
    this.isGroupView = this.router.url.includes('group');
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    if (this.groupViewMode) {
      this.sortOptions.pop();
    }
    this.currentUser = await this.userService.getCurrentUser();
    this.isAdmin =
      this.userService.hasPermissionHierarchy(AppLevelRoleEnum.ENTITY_LEADER, [entityId]) ||
      !this.currentUser?.isViewOnly;
    this.isCrbAdjustments = UtilsService.isCRB;
    this.showAddMPL =
      this.isCrbAdjustments &&
      this.isMultiEntityRoute &&
      this.userService.hasPermissionHierarchy(AppLevelRoleEnum.ENTITY_LEADER, [entityId]);

    this.showAddEntity =
      !this.showAddMPL &&
      (this.router.url.includes('clients') ||
        (this.isMultiEntityRoute &&
          this.userService.hasPermissionHierarchy(AppLevelRoleEnum.ENTITY_LEADER, [entityId])));
    // Show new subentity add modal if route contains multi-entity
    this.showAddEntityNew = !this.isCrbAdjustments && this.isMultiEntityRoute;
    this.showDeleteEntity = this.showAddEntity;

    if (this.inputCollection) {
      // change the inputCollection reference
      this.cachedCollection = this.displayCollection = this.inputCollection.map(item => item);
    }

    if (this.defaultSortBy && this.displayCollection && this.displayCollection?.length) {
      this.sortChanged(this.defaultSortBy);
    }
  }
  sortChanged(sortBy) {
    if (!sortBy && this.defaultSortBy) {
      sortBy = this.defaultSortBy;
    }
    this.sorter = sortBy;
    if (sortBy !== this.prevSortBy) {
      this.prevSortBy = sortBy;
      switch (sortBy) {
        case 'name':
          this.displayCollection = !this.groupViewMode
            ? this.displayCollection
            : JSON.parse(JSON.stringify(this.cachedCollection));
          this.displayCollection = this.displayCollection.sort((a, b) => {
            const first = a.name.toLowerCase().trim();
            const second = b.name.toLowerCase().trim();
            return first.localeCompare(second); // Directly compare strings
          });

          break;
        case 'score':
          if (!this.groupViewMode) {
            this.displayCollection = this.displayCollection.sort((a, b) => {
              const aScore =
                a.scores.tierScores && a.tierSelected
                  ? JSON.parse(a.scores.tierScores)[a.tierSelected]
                  : a.scores.total;
              const bScore =
                b.scores.tierScores && b.tierSelected
                  ? JSON.parse(b.scores.tierScores)[b.tierSelected]
                  : b.scores.total;

              return Math.sign(bScore - aScore);
            });
          } else {
            this.displayCollection = JSON.parse(JSON.stringify(this.cachedCollection));
            this.displayCollection = this.displayCollection.sort((a, b) => {
              return a.score - b.score;
            });
          }

          break;
        case 'target':
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.scores.target > b.scores.target ? 1 : b.scores.target > a.scores.target ? -1 : 0;
          });
          break;
        // if no sorter applied, then sort on the based of createdAt in descending order
        default:
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0;
          });
          break;
      }
    }
    this.prevSortBy = '';
    this.onSearchChanged.emit(this.displayCollection);
  }

  // function to filter entities on the base of scores
  check(state: string, value: number) {
    switch (state.toLowerCase()) {
      case 'low':
        return value <= 3.33;
        break;
      case 'medium':
        return value > 3.33 && value < 6.66;
        break;
      case 'high':
        return value >= 6.66;
        break;
    }
  }

  inputBasedSearch(filterBy: string) {
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.onSearchTextChanged.emit(filterBy);
      this.overallSearchandFilters();
    }, 1000);
  }

  filterChanged(filterBy) {
    this.lastFilterByValue = filterBy;
    this.overallSearchandFilters();
  }

  overallSearchandFilters() {
    // commenting this code for git history
    // if (this.isVendorsTable) {
    //   this.vendorListFilterChanged();
    // }

    if (this.groupViewMode) {
      this.displayCollection = this.displayCollection.length
        ? this.displayCollection
        : JSON.parse(JSON.stringify(this.cachedCollection));

      if (this.queryTextfromInput) {
        this.displayCollection = JSON.parse(JSON.stringify(this.cachedCollection));
        this.displayCollection = this.displayCollection.filter(item => {
          return item.name.toLowerCase().includes(this.queryTextfromInput.toLowerCase());
        });
        this.onSearchChanged.emit(this.displayCollection);
      }

      if (!this.lastFilterByValue && !this.queryTextfromInput && UtilsService.isDefined(this.cachedCollection)) {
        this.onFilterChanged.emit([...this.cachedCollection]);

        return;
      } else if (this.lastFilterByValue) {
        this.displayCollection = JSON.parse(JSON.stringify(this.cachedCollection));
        this.displayCollection = this.displayCollection.filter(group => {
          if (this.check(this.lastFilterByValue, group.score)) {
            return group;
          }
        });
        if (UtilsService.isDefined(this.displayCollection)) {
          this.onFilterChanged.emit([...this.displayCollection]);
        }
      }
    } else {
      this.displayCollection = this.cachedCollection.map(item => item);
      if (this.queryTextfromInput) {
        this.displayCollection = this.displayCollection.filter(item => {
          return item.name.toLowerCase().includes(this.queryTextfromInput.toLowerCase());
        });
      }
      if (this.lastFilterByValue) {
        this.displayCollection = this.displayCollection.filter(item => {
          if (item.scores && item.scores.tierScores && item.tierSelected) {
            const s = JSON.parse(item.scores.tierScores);
            if (this.check(this.lastFilterByValue, s[item.tierSelected])) {
              return item;
            }
          } else {
            if (this.check(this.filterBy, item.scores.total)) {
              return item;
            }
          }
        });
      }
      if (this.sorter) {
        this.sortChanged(this.sorter);
      }
      if (this.sortBy) {
        this.sortChanged(this.sortBy);
      }
      this.onSearchChanged.emit(this.displayCollection);
    }
  }

  viewChanged(isCard) {
    // emit only if there was a change
    if (isCard !== this.cardView) {
      this.cardView = isCard;
      this.onViewChanged.emit(isCard);
    }
  }

  addClientModal() {
    const modalRef = this.modalService.open(AddClientModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-client-modal-windowClass',
      backdropClass: 'add-client-modal-backdrop',
    });
    modalRef.componentInstance.modalResult.subscribe(async (event: any) => {
      if (event === true) {
        modalRef.close();
        return;
      }
      setTimeout(() => {
        modalRef.close();
      }, 1000);

      const aiData = event.entity?.AI ? { ...event.entity.AI } : null;
      if (aiData) {
        delete event.entity.AI;
      }
      const createdEntity = await this.createEntity(event);

      if (aiData) {
        await this.customApiService.allocateAICredits({
          id: createdEntity.id,
          AI: aiData,
        });
      }
      this.scanAllDomains(createdEntity);
    });
  }

  async scanAllDomains(createdEntity): Promise<void> {
    if (
      createdEntity?.domainScanEnabledForEntity &&
      createdEntity?.domainScanTimes &&
      createdEntity?.solutionStatus?.firstParty
    ) {
      const domainScan = JSON.parse(createdEntity.domainScanTimes);
      if (domainScan?.length) {
        const domainScanValue = domainScan[0].name;
        const domainList = [domainScanValue];
        this.toastr.info('Scanning your domain in the background. Results will be available shortly!');
        const allFiles = await this.fileService.checkIfPathExistsS3(`EXTERNAL_SCANS/${createdEntity.id}`);
        if (allFiles && allFiles.length) {
          allFiles.forEach(file => {
            const domName = file.key.split('/')[2];
            const dom = domName.split('.zip')[0];
            const index = domainList.findIndex(d => d === dom);
            if (index >= 0) {
              domainList.splice(index, 1);
            } else {
              // only keep the file related to updated domain,al other files should be deleted
              this.fileService.deleteFromS3(file);
            }
          });
        }
        if (!domainList.length) {
          this.toastr.success('The domain scan has been completed! The results are now available in the Upperdeck.');
          return;
        }
        const json = {
          wizardId: createdEntity.id,
          isRootEntity: true,
          domainList,
        };
        const credentials = await Auth.currentCredentials();
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials),
          region: environment.region,
          httpOptions: {
            timeout: 900000, // Set timeout to 900000 milliseconds (15 minutes)
          },
        });
        const params = {
          FunctionName: `multiDomainBinaryScan-${environment.name}`,
          Payload: JSON.stringify({ ...json }),
        };
        try {
          await lambda.invoke(params).promise();
          // this.toastr.success('The domain scan has been completed! The results are now available in the Upperdeck.');
        } catch (error) {
          this.toastr.error('The domain scan has failed, please try and re-run later.');
          console.log('Issue while scanning domains ' + JSON.stringify(error));
        }
      }
    }
  }

  addSubEntityModal() {
    const modalRef = this.modalService.open(AddClientModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-client-modal',
    });
    modalRef.componentInstance.modalResult.subscribe((event: any) => {
      this.createEntity(event);
      modalRef.close();
    });
  }

  // NEW MODAL
  addNewClientModal() {
    const modalRef = this.modalService.open(AddNewClientModalComponent, {
      size: 'lg',
      windowClass: 'add-new-client-modal-windowClass',
      backdropClass: 'add-new-client-backdrop-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalResult.subscribe((event: any) => {
      this.createEntity(event);
      modalRef.close();
    });
  }

  addEntityMPLModal() {
    const modalRef = this.modalService.open(AddEntityModalComponent, {
      size: 'lg',
      windowClass: 'add-new-client-modal-windowClass',
      backdropClass: 'add-new-client-backdrop-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalResult.subscribe((event: any) => {
      this.createEntity(event);
      modalRef.close();
    });
  }

  async updateEntity(event) {
    const { s3Input, entity } = event;
    try {
      if (s3Input) {
        try {
          event.entity.logo = await this.fileService.uploadToS3(event.s3Input);
        } catch (e) {
          this.logger.error('uploadLogoToS3 - Error: ', e);
          this.toastr.error('Failed to upload Logo');
        }
      }

      this.toastr.info('Creating Entity...');
      const updatedEntity = await this.entityService.updateEntity(entity);

      this.toastr.success(`${updatedEntity.name} has updated successfully!`);
      // this will stop loader in clients component
      this.entityService.isLoadingSub.next(false);
    } catch (error) {
      this.toastr.error(`Failed to create ${entity.name}`);
    }
  }

  async createEntity(event) {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    if (!event) {
      return;
    }
    if (event.s3Input) {
      try {
        event.entity.logo = await this.fileService.uploadToS3(event.s3Input);
      } catch (e) {
        this.logger.error('uploadLogoToS3 - Error: ', e);
        this.toastr.error('Failed to upload Logo');
      }
    }
    try {
      let isHitrust = false;
      event.requiredStandard.forEach(standard => {
        if (standard.name === 'HITRUST') {
          isHitrust = true;
        }
      });
      // const standards = JSON.parse(JSON.stringify(event.requiredStandard));
      // if (isHitrust) {
      //   standards.splice(
      //     standards.findIndex(i => i.key === StandardEnum.HITRUST),
      //     1
      //   );
      // }
      if (isHitrust) {
        this.uploadFileModalReference = this.modalService.open(UploadFileComponent, this.ngbModalUploadOptions);
        this.toastr.info(
          `Please note a version of Hi-Trust of your own copy is mandatory. 
          Not uploading the file will result in the Entity not being created.`
        );
        this.uploadFileModalReference.componentInstance.isHiTrust = true;
        this.uploadFileModalReference.componentInstance.isFakeUpload = true;
        this.uploadFileModalReference.componentInstance.uploadPath = `ASSESSMENTS/${event.entity.activeAssessmentId}/hitrust-verification`;
        this.uploadFileModalReference.componentInstance.requiredInfo = {
          fileType: 'HITRUST',
          assessmentId: event.entity.activeAssessmentId,
        };
        // passing additional restriction to what type of files can be uploaded
        this.uploadFileModalReference.componentInstance.uploadType = '.csv , csv';
        this.uploadFileModalReference.componentInstance.restrictFileDrop = '.csv';
        this.uploadFileModalReference.componentInstance.closeModalClicked.subscribe((response: any) => {
          if (response) {
            this.uploadFileModalReference.close();
            if (typeof response !== 'boolean') {
              this.afterFakeUpload(
                event.entity,
                event.requiredStandard,
                event.entity.activeAssessmentId,
                'HITRUST',
                response
              );
            } else {
              this.toastr.error(' Failed to create. File Upload Mandatory for Hi-Trust Compliance');
            }
          }
        });
      } else {
        this.toastr.info('Creating Entity...');
        const createdEntity = await this.entityService.createEntity(
          event.entity,
          event.requiredStandard,
          event.newAddedUsers,
          entityId,
          event.selectedChapters,
          event.frameworkReviewers,
          event.needToCreateAssignments,
          event.isFirstParty
        );

        if (!this.isMultiEntityRoute) {
          this.toastr.success(`${createdEntity.name} created successfully!`);
          // this will stop loader in clients component
          this.entityService.isLoadingSub.next(false);
        }

        return createdEntity;
      }
    } catch (e) {
      this.toastr.error(`Failed to create ${event.entity.name}`);
    }
  }

  async afterFakeUpload(entity, standards, assessmentId, fileType, file) {
    this.toastr.info('Creating Entity...');
    const newEntity = await this.entityService.createEntity(entity, standards);
    if (newEntity) {
      const fileInfo = {
        id: uuid.v4(),
        assessmentId,
        body: file,
        name: file.name,
        fileType,
      };
      const upload = await this.fileService.uploadToS3(fileInfo);
      if (upload) {
        this.toastr.success(`${newEntity.name} created successfully!`);
      }
    }
  }
  showDeletePopUp(): void {
    const message = this.router.url.includes('clients') ? 'Entity' : 'Sub Entity';
    if (this.enableDelete) {
      const modalRef = this.modalService.open(DeleteModalComponent, this.ngbModalDeleteFrameworkOptions);
      modalRef.componentInstance.titleText = '';
      modalRef.componentInstance.show2ndConfirmText = 'All your data will be lost';
      modalRef.componentInstance.removeLabelText = this.entitySelected;
      modalRef.componentInstance.deleteEntityModal = true;
      modalRef.componentInstance.svgName = 'trash-icon';
      modalRef.componentInstance.entityType = message.toLowerCase() === 'entity' ? message.toLowerCase() : 'sub-entity';
      modalRef.componentInstance.deleteFramework.subscribe((deleteConfirm: boolean) => {
        if (deleteConfirm) {
          this.deleteEntityTrigger.emit(true);
        }
      });
    } else {
      this.toastr.info('No ' + message + ' Selected...');
    }
  }
  enableEditMode() {
    this.editModeTrigger.emit(true);
  }
  disableEditMode() {
    this.editModeTrigger.emit(false);
  }
}
