export enum EXPORT_OPTIONS {
  EXPORT_XLS = 'General Report (XLS)',
  EXPORT_CSV = 'General Report (CSV)',
  EXPORT_CURRENT_XLS = 'Current View (XLS)',
  EXPORT_CURRENT_CSV = 'Current View (CSV)',
}

export const CYBER_ARK_FRAMEWORKS = ['CAIQ', 'CAIQ_V1'];

//* Email type ENUM
export enum EMAIL_TYPE {
  NOTIFY_USER_NEW_TASK_ASSIGN = 'NOTIFY_USER_NEW_TASK_ASSIGN',
}

export enum FilterEnums {
  'entities' = 'Entity',
  'tags' = 'Tag',
  'groups' = 'Group',
  'inherentImpact' = 'Inherent | Impact',
  'inherentProbability' = 'Inherent | Probability',
  'residualImpact' = 'Residual | Impact',
  'residualProbability' = 'Residual | Probability',
  'riskOwner' = 'Risk Owner',
  'financial' = 'Financial',
  'source' = 'Source',
  'status' = 'Status',
}
export enum FilterCheckEnum {
  RISK_OWNER = 'riskOwner',
  VIEW = 'view',
  TAGS = 'tags',
  GROUPS = 'groups',
  TOTAL = 'Total',
  FINANCIAL = 'financial',
  FINANCIAL_WITH = 'With',
  FINANCIAL_WITHOUT = 'Without',
  SOURCE = 'source',
  ENTITIES = 'entities',
}

//* To Enable Answers for Task Owner in Remediation Single Mode
export const TASK_OWNER_ENABLED_ANSWERS = ['yes', 'yes - in place', 'not applicable'];
