<div class="main-container-custom" *ngIf="isNetSkope" #customDropDownArea>
  <div class="custom-drop-container"
    [@colorOpener]="{value: mode, params: {closed: closedColor , opened:openedColor}}"
    [ngStyle]="{width:widthArea}">
    <div class="arrow-opener" (click)="openDrop(isDropDownOpen = !isDropDownOpen)">
      <div class="arrow-icon-custom" [ngClass]="{'expandClass-custom':isDropDownOpen}" cygovSvgIcon
        [svgName]="'arrow-right-type3'">
      </div>
    </div>
    <div class="selected-option-custom lato-14-n-vw"><span>{{selectedItem | replace:'_':' ' |
        titlecase }}</span></div>
  </div>
  <div class="option-list-custom" *ngIf="isDropDownOpen"
    [@heightUpdate]="isDropDownOpen ? 'appear':'void'">
    <ng-container *ngFor="let item of itemList; let idx = index">
      <div *ngIf="idx" class="option-item-custom lato-14-n-vw" (click)="optionSelected(item,idx)">
        <span>{{item | replace:'_':' ' | titlecase}}</span>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="!isNetSkope" class="custom-dropdown">
  <div class="dropdown" [ngStyle]="{'width' : widthArea}">
    <div class="title lato-16-n-vw"
      [ngStyle]="{'background-color' : isDropDownOpen ? opened : closed}"
      (click)="toggleDropDown()">
      <div class="title-text">
        <div *ngIf="!selectedItems.length" class="lbl-text">{{ title }}</div>
        <div *ngIf="isDropDownOpen && selectedItems.length" class="selected-lbl-text">{{
          singleSelect ?
          selectedItems[0][bindLabel] : title }}</div>
        <ng-container *ngIf="!isDropDownOpen && selectedItems.length">
          <ng-container *ngFor="let item of selectedItems; let i = index; let last = last">
            <div class="wrapper">
              <span class="text">{{item[bindLabel]}}</span>
              <span *ngIf="!showCross && !last">,&nbsp;&nbsp; </span>
              <div *ngIf="showCross" class="close-row" (click)="removeItem($event, item, i)">
                <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'">
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="showMore" class="more-button">More...</div>
      <div class="arrow-icon-right" [ngClass]="{'rotate-arrow-down':isDropDownOpen}" cygovSvgIcon
        [svgName]="'arrow-right-type3'">
      </div>
    </div>
    <div class="expanded-dropdown" *ngIf="isDropDownOpen"
      [ngStyle]="{'width' : widthArea, 'background-color': itemsColor}">
      <div *ngIf="showSearch" class="search-wrapper">
        <div class="search-icon" cygovSvgIcon [svgName]="searchIconSvgName"></div>
        <input type="text" class="form-control search" aria-label="Search" placeholder="Search"
          [(ngModel)]="queryTextfromInput" name="search" spellcheck="true" (keyup)="searchList()" />
      </div>
      <div *ngIf="itemList && itemList.length" class="list-items-wrapper">
        <ng-container *ngFor="let item of itemList; let i = index;">
          <div class="item-row lato-14-l-vw">
            <cygov-checkbox *ngIf="showCheckbox" class="access-checkbox" [id]="'item-'+i"
              [isChecked]="item.selected" [height]="12" [width]="12"
              (changed)="selectItem(item)"></cygov-checkbox>
            <span [ngStyle]="{color: item.selected ? selectedColor : null}"
              (click)="selectItem(item)">{{item[bindLabel]}}</span>
          </div>
          <div *ngIf="customSeprator" class="horizontal-seprator"></div>
        </ng-container>
      </div>
      <ng-container *ngIf="itemList && !itemList.length">
        <span class="no-message">{{isSubEntitySelection? 'No Sub Entity' : 'No Industry' }}</span>
      </ng-container>
    </div>
  </div>
</div>