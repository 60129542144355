import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/shared/file.service';
import { CreateEntityInput, EntityTypeEnum, FileTypeEnum, GetUserQuery, S3FileInput } from 'app/API.service';
import { IndustryEnumBeecher } from 'app/shared/enums/brownAndBrown.enum';
import { EntityService, RequiredStandard } from 'app/shared/entity.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { RiskOption } from 'models/generator.model';
import { NgbActiveModal, NgbDateStruct, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/shared/user.service';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { DomainFrameworksListingsComponent } from 'app/shared/domain-frameworks-listings/domain-frameworks-listings.component';
import { DeleteModalComponent } from 'app/shared/delete-modal/delete-modal.component';
import { UPPERDECK_FACELIFT } from 'app/shared/enums/shared.enum';

@Component({
  selector: 'cygov-add-client-modal',
  templateUrl: './add-client-modal.component.html',
  styleUrls: ['./add-client-modal.component.scss'],
})
export class AddClientModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<any>();
  @ViewChild(DomainFrameworksListingsComponent) domainFrameworksListingsComponent!: DomainFrameworksListingsComponent;
  isEditMode: boolean = false;
  currentEntity;
  newEntity;
  imageFile: any;
  riskFramework: RiskOption;
  s3Input: S3FileInput;
  standardList: RequiredStandard[];
  frameworkTypeOpt = [EntityTypeEnum.RISK_FRAMEWORK, EntityTypeEnum.COMPLIANCE_FRAMEWORK];
  displayStandardList: boolean;
  industryOpt: any = [];
  riskFrameWorkOpt: RiskOption[] = [];
  entityType: EntityTypeEnum;
  assessmentDate: Date;
  deadlineDate: Date;
  DEADLINE_DATE = 'deadlineDate';
  ASSESSMENT_DATE = 'assessmentDate';
  currentDate: NgbDateStruct;
  uploadedLogo: any;
  placeholderMandatory: string = 'Select Industry *';
  RiskFrameworkEnum: any;
  invalidInput: boolean = false;
  industryDropdown = {
    value: '',
    isDropDownOpened: false,
    list: [],
    searchList: [],
  };
  businessDropDown: boolean = false;
  solutionObject = {
    '1st Party': { checked: true },
    '3rd Party': { checked: true },
    Boardview: { checked: true },
  };
  DomainScanValue = null;
  vendorList = [];
  ngbModalDeleteFrameworkOptions: NgbModalOptions = {
    size: 'sm',
    windowClass: 'delete-entity-modal delete-collection-framework-modal',
    backdropClass: 'delete-entity-backdrop-modal delete-collection-framework-backdrop-modal',
    backdrop: 'static',
  };

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public activeModal: NgbActiveModal,
    private entityService: EntityService,
    private domainFrameworkService: DomainFrameworkService,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.RiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.RISK_FRAMEWORK_ENUM);
  }

  async ngOnInit(): Promise<void> {
    // TODO: Add input "Select Project Manager" it's mandatory. In the mean time it will be the current user
    const projectManager: GetUserQuery = await this.userService.getCurrentUser();
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.entityType = this.router.url.includes('clients') ? EntityTypeEnum.ROOT_ENTITY : EntityTypeEnum.CHILD_ENTITY;

    const industries = Object.keys(IndustryEnumBeecher);
    this.industryDropdown.list = industries
      .map(o => {
        return {
          name: IndustryEnumBeecher[o],
        };
      })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    this.industryDropdown.searchList = JSON.parse(JSON.stringify(this.industryDropdown.list));

    if (this.isEditMode) {
      this.newEntity = JSON.parse(JSON.stringify(this.currentEntity));
      this.solutionObject = {
        '1st Party': { checked: this.newEntity?.solutionStatus?.firstParty ?? true },
        '3rd Party': { checked: this.newEntity?.solutionStatus?.thirdParty ?? true },
        Boardview: { checked: this.newEntity?.solutionStatus?.boardView ?? true },
      };
      if (!this.newEntity?.solutionStatus) {
        this.newEntity = {
          ...this.newEntity,
          solutionStatus: {
            firstParty: true,
            thirdParty: true,
            boardView: true,
          },
        };
      }

      if (this.newEntity?.domainScanned === null) {
        this.newEntity.domainScanned = true;
        this.newEntity.domainScanEnabledForEntity = true;
      }
      if (this.newEntity?.domainScanTimes) {
        // this.DomainScanValue = JSON.parse(this.newEntity.domainScanTimes)[0].name;
        const domainScan = JSON.parse(this.newEntity.domainScanTimes);
        if (domainScan?.length) {
          this.DomainScanValue = domainScan[0].name;
          this.newEntity.domainScanned = true; // Enable domain scanned
          this.newEntity.domainScanEnabledForEntity = true; // Enable domain scan toggle
        }
      }
      this.industryDropdown.value = this.newEntity.industry;

      if (!this.newEntity?.AI || !Object.keys(this.newEntity.AI).length) {
        this.newEntity.AI = {
          credits: 100,
        };
      }

      if (!this.newEntity?.vendors || !Object.keys(this.newEntity.vendors).length) {
        this.newEntity.vendors = {
          vendorsTotalCount: 50,
        };
      }

      this.uploadedLogo = (await this.entityService.setLogoUrl(this.newEntity)).logoUrl;
    } else {
      this.newEntity = EntityService.initEntity(this.entityType, projectManager.id, entityId);
      this.newEntity.tierSelected = 4;
      this.newEntity.domainScanned = true;
      this.newEntity.domainScanEnabledForEntity = true;
      this.newEntity.domainScanTimes = null;
      this.newEntity.AI = { credits: 100 };
      this.newEntity.vendors = { vendorsTotalCount: 50, totalVendorsCreated: 0 };
      this.newEntity.domainScanTimes = null;
      this.newEntity.solutionStatus = {
        firstParty: true,
        thirdParty: true,
        boardView: true,
      };
    }

    this.applyFrameworkSettings();
    // this.standardList = this.entityService.initStandardList();
    // this.displayStandardList = this.router.url.includes('multi-entity');
    // this.prepareRiskFrameworkOptions();
    this.currentDate = UtilsService.getDateInNgbDateStructFormat(new Date());
  }

  checkIfNewFrameworkAdded(domainFrameworks, entityFrameworks) {
    domainFrameworks.forEach(item => {
      // Check if the item from array1 is not present in array2
      const isInArray2 = entityFrameworks.some(obj => obj.name === item.name);
      // Add the object with status true to array2 if it's not already present
      if (!isInArray2 && item.status === true) {
        entityFrameworks.push({ ...item, status: false });
      }
    });

    entityFrameworks = entityFrameworks.filter(
      item => domainFrameworks.some(obj => obj.name === item.name && obj.status === true) || item.status === true
    );

    return entityFrameworks;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  applyFrameworkSettings() {
    try {
      let frameworkSettings = null;
      if (this.isEditMode) {
        frameworkSettings = this.newEntity?.frameworkSettings ? JSON.parse(this.newEntity?.frameworkSettings) : null;
        const domainSettings = this.domainFrameworkService.domainSettingsData;

        frameworkSettings = {
          RISK_FRAMEWORKS: this.checkIfNewFrameworkAdded(
            domainSettings?.RISK_FRAMEWORKS || [],
            frameworkSettings?.RISK_FRAMEWORKS || []
          ),
          COMPLIANCE_FRAMEWORKS: this.checkIfNewFrameworkAdded(
            domainSettings?.COMPLIANCE_FRAMEWORKS || [],
            frameworkSettings?.COMPLIANCE_FRAMEWORKS || []
          ),
          VENDOR_FRAMEWORKS: this.checkIfNewFrameworkAdded(
            domainSettings?.VENDOR_FRAMEWORKS || [],
            frameworkSettings?.VENDOR_FRAMEWORKS || []
          ),
        };
      }
      if (!frameworkSettings) {
        frameworkSettings = this.domainFrameworkService.domainSettingsData;
      }

      // risk
      this.riskFrameWorkOpt = [
        ...frameworkSettings.RISK_FRAMEWORKS.filter(vendorFramework => {
          if (this.isEditMode) {
            return true;
          } else {
            return !!vendorFramework.status;
          }
        }),
      ];

      // compliance
      this.standardList = [
        ...frameworkSettings.COMPLIANCE_FRAMEWORKS.filter(vendorFramework => {
          if (this.isEditMode) {
            return true;
          } else {
            return !!vendorFramework.status;
          }
        }),
      ];

      this.vendorList = [
        ...frameworkSettings.VENDOR_FRAMEWORKS.filter(vendorFramework => {
          if (this.isEditMode) {
            return true;
          } else {
            return !!vendorFramework.status;
          }
        }),
      ];
    } catch (error) {
      console.log({ error });
    }
  }

  // prepareRiskFrameworkOptions(): void {
  //   const riskList =
  //     UtilsService.isBnB || UtilsService.isBnBCyberSite
  //       ? [this.RiskFrameworkEnum.NIST_CSF_BB.split(' ').join('_')]
  //       : Object.keys(this.RiskFrameworkEnum);
  //   for (const enumMember of riskList) {
  //     this.riskFrameWorkOpt.push({
  //       key: enumMember,
  //       value: this.RiskFrameworkEnum[enumMember],
  //     });
  //   }
  // }

  toggleStandard(event, standard: RequiredStandard): void {
    UtilsService.stopBubbling(event);
    standard.checked = !standard.checked;
  }

  async imgHandler(eventFile: Event): Promise<void> {
    try {
      const value = await this.fileService.verifyUploadFile(eventFile, FileTypeEnum.LOGO);
      if (!value) {
        return;
      }
      this.s3Input = await this.fileService.convertToS3Input(eventFile, this.newEntity.id);
      this.uploadedLogo = await this.fileService.getUploadedImg(eventFile);
    } catch (error) {
      this.logger.error('Image Handler  Error =', error);
      this.toastr.error(error);
      // eventFile = null;
      // this.s3Input = null;
      // this.uploadedLogo = previousLogo;
    }
  }

  isValid(entity: CreateEntityInput): boolean {
    let valid = true;
    if (!entity.name) {
      this.toastr.error('Entity name is required');
      valid = false;
    } else if (UtilsService.checkForHtml(entity.name)) {
      this.toastr.error('HTML tags are not allowed in the entity name');
      valid = false;
    } else {
      if (entity.name.length > 30) {
        this.toastr.error('Entity name cannot exceed 30 characters');
        valid = false;
      }
    }
    if (this.entityType === EntityTypeEnum.ROOT_ENTITY) {
      if (!entity.industry) {
        this.toastr.error('Entity Industry is required');
        valid = false;
      }
    }
    return valid;
  }

  dateChanged(date, dateType: string): void {
    this[dateType] = date;
  }

  emitEntity(groupedFrameworks): void {
    const updatedSettings = {
      RISK_FRAMEWORKS: groupedFrameworks.RISK_FRAMEWORKS,
      COMPLIANCE_FRAMEWORKS: groupedFrameworks.COMPLIANCE_FRAMEWORKS,
      VENDOR_FRAMEWORKS: groupedFrameworks.VENDOR_FRAMEWORKS,
    };
    const filterRisk = updatedSettings.RISK_FRAMEWORKS.filter(risk => risk.status === true);
    if (filterRisk.length === 0) {
      this.toastr.error('Select at least one Risk Framework');
      return;
    }
    if (this.newEntity.solutionStatus.thirdParty === true) {
      const filterVendor = updatedSettings.VENDOR_FRAMEWORKS.filter(risk => risk.status === true);
      if (filterVendor.length === 0) {
        this.toastr.error('Select at least one Vendor Framework');
        return;
      }
    }
    this.entityService.updateIsLoading(true);
    this.newEntity.frameworkSettings = JSON.stringify(updatedSettings);
    this.newEntity.domainScanTimes = JSON.stringify(
      this.DomainScanValue ? [{ name: this.DomainScanValue, updated: Date.now() }] : []
    );

    this.newEntity.upperdeckSetting = this.newEntity.domainScanEnabledForEntity
      ? UPPERDECK_FACELIFT.RESIDUAL_RISK_ATTACK_SURFACE
      : UPPERDECK_FACELIFT.RESIDUAL_RISK;

    console.log('new Entity', this.newEntity);

    this.modalResult.emit({
      entity: this.newEntity,
      s3Input: this.s3Input,
      requiredStandard: [],
    });
  }

  openBusinessDropdown(obj: any): void {
    this.industryDropdown.searchList = JSON.parse(JSON.stringify(this.industryDropdown.list));
    obj.isDropDownOpened = !obj.isDropDownOpened;
    const event = obj.isDropDownOpened;

    if (this.industryDropdown.isDropDownOpened) {
      this.businessDropDown = event;
    }

    if (!event) {
      this.industryDropdown.isDropDownOpened = false;
    }
  }

  saveBusinessDropdownItem(obj: any, index: number): void {
    this.industryDropdown.isDropDownOpened = false;
    this.businessDropDown = false;
    obj.value = obj.searchList[index].name;
    this.newEntity.industry = obj.value;
  }
  searchInDropdownList(event) {
    const inputText = (event.target as HTMLInputElement).value;
    this.industryDropdown.searchList = JSON.parse(
      JSON.stringify(
        this.industryDropdown.list.filter(item => item.name.toLowerCase().includes(inputText.toLocaleLowerCase()))
      )
    );
  }

  closeBusinessDetailDropdown(): void {
    this.industryDropdown.isDropDownOpened = false;
    this.businessDropDown = false;
  }
  beforeCheckBoxChnaged(event) {
    const checkboxId = (event.target as HTMLInputElement).id;
    if (
      checkboxId === '1st Party' &&
      this.solutionObject[checkboxId].checked &&
      !this.solutionObject['3rd Party'].checked
    ) {
      event.preventDefault();
    }

    if (
      checkboxId === '3rd Party' &&
      this.solutionObject[checkboxId].checked &&
      !this.solutionObject['1st Party'].checked
    ) {
      event.preventDefault();
    }
  }

  checkboxChangeHandler(event, item) {
    if (item.key === 'Boardview') {
      this.solutionObject['1st Party'].checked = event === false ? this.solutionObject['1st Party'].checked : true;
      this.newEntity.solutionStatus.firstParty = this.solutionObject['1st Party'].checked;
      this.solutionObject.Boardview.checked = event;
      this.newEntity.solutionStatus.boardView = event;
    } else if (item.key === '1st Party') {
      this.newEntity.solutionStatus.firstParty = event;
      this.newEntity.domainScanned = event === false ? false : true;
      this.newEntity.domainScanEnabledForEntity = this.newEntity.domainScanned;
      this.DomainScanValue = this.newEntity.domainScanEnabledForEntity ? this.DomainScanValue : '';
      this.newEntity.solutionStatus.boardView = event === false ? false : this.newEntity.solutionStatus.boardView;
      this.solutionObject.Boardview.checked = this.newEntity.solutionStatus.boardView;
    } else {
      this.newEntity.solutionStatus.thirdParty = event;
    }
    item.value.checked = event;
  }

  increment(label: string, inputField: HTMLInputElement) {
    let currentValue = parseFloat(inputField.value);

    // Add the same value to the current input value
    if (label === 'AI_CREDITS') {
      currentValue = this.newEntity.AI.credits + 100;
      this.newEntity.AI.credits = currentValue;
    } else if (label === 'VENDORS_COUNT') {
      currentValue = this.newEntity.vendors.vendorsTotalCount + 50;
      this.newEntity.vendors.vendorsTotalCount = currentValue;
    }
    // Update the input field's value
    inputField.value = currentValue.toString();
  }

  decrement(label: string, inputField: HTMLInputElement) {
    let currentValue = parseFloat(inputField.value);

    // Subtract the same value from the current input value
    if (label === 'AI_CREDITS' && currentValue > 100) {
      currentValue = this.newEntity.AI.credits - 100;
      this.newEntity.AI.credits = currentValue;
    } else if (label === 'VENDORS_COUNT' && currentValue > 50) {
      currentValue = this.newEntity.vendors.vendorsTotalCount - 50;
      this.newEntity.vendors.vendorsTotalCount = currentValue;
    }
    // Update the input field's value
    inputField.value = currentValue.toString();
  }

  saveButtonClicked() {
    this.domainFrameworksListingsComponent.saveButtonEventHandler();
  }

  deleteClientModal() {
    const message = this.router.url.includes('clients') ? 'Entity' : 'Sub Entity';
    const modalRef = this.modalService.open(DeleteModalComponent, this.ngbModalDeleteFrameworkOptions);
    modalRef.componentInstance.titleText = '';
    modalRef.componentInstance.show2ndConfirmText = 'All your data will be lost';
    modalRef.componentInstance.removeLabelText = this.newEntity.name;
    modalRef.componentInstance.deleteEntityModal = true;
    modalRef.componentInstance.svgName = 'trash-icon';
    modalRef.componentInstance.entityType = message.toLowerCase() === 'entity' ? message.toLowerCase() : 'sub-entity';
    modalRef.componentInstance.deleteFramework.subscribe((deleteConfirm: boolean) => {
      if (deleteConfirm) {
        this.modalResult.emit('delete');
      }
    });
  }
}
