export enum FrameworkLevelBackend {
  VENDOR_SAQ_TIER_1 = 'VENDOR_RISK_INFORMATICA_1',
  VENDOR_SAQ_TIER_2 = 'VENDOR_RISK_INFORMATICA_2',
  VENDOR_SAQ_TIER_3 = 'VENDOR_RISK_INFORMATICA_3',
  OSAE_FULL = 'VENDOR_RISK_OSAE',

  HOWDEN_CYBER_360 = 'VENDOR_RISK_HOWDEN_CYBER_360',
  HOWDEN_RANSOMWARE = 'VENDOR_RISK_HOWDEN_RANSOMWARE',
  HOWDEN_OLD = 'VENDOR_RISK_HOWDEN',
  HOWDEN_FULL = 'VENDOR_RISK_HOWDEN_FULL',

  VENDOR_RISK_ITC_FULL = 'VENDOR_RISK_ITC',

  VENDOR_RISK_CRB_FULL = 'VENDOR_RISK_CRB',

  BA_HIPAA_FULL = 'VENDOR_RISK_BA_HIPAA',
  SSM_HEALTH_VENDOR_RISK_FULL = 'VENDOR_RISK_SSM_HEALTH',

  VENDOR_RISK_ENBD_FULL = 'VENDOR_RISK_ENBD',
  VENDOR_RISK_SAQ_LIGHT_FULL = 'VENDOR_RISK_ENBD_SAQ_LIGHT',
  VENDOR_RISK_SOC2_FULL = 'VENDOR_RISK_ENBD_SOC2',

  VENDOR_SAQ_FULL = 'VENDOR_RISK_SAQ',
  VENDOR_SAQ_V2 = 'VENDOR_RISK_SAQ_V2',
  VENDOR_PRE_SAQ_FULL = 'VENDOR_RISK_PRESAQ',
  VENDOR_PRE_SAQ_V2 = 'VENDOR_RISK_PRESAQ_V2',

  'VENDOR_RISK_(M)_FULL' = 'VENDOR_RISK_MOELIS',
  'VENDOR_RISK_(M1)_FULL' = 'VENDOR_RISK_MOELIS_M1',

  CENTRALEYES_SAQ_FULL = 'VENDOR_RISK',
  CENTRALEYES_SAQ_LIGHT = 'VENDOR_RISK_LIGHT',
  CENTRALEYES_SAQ_MODERATE = 'VENDOR_RISK_MODERATE',
  CENTRALEYES_SAQ_HIGH = 'VENDOR_RISK_HIGH',

  HECVAT_LIGHT = 'VENDOR_RISK_HECVAT_LIGHT',
  HECVAT_ONPREM = 'VENDOR_RISK_HECVAT_ONPREM',
  HECVAT_FULL = 'VENDOR_RISK_HECVAT',

  'CSA_(VENDOR)_FULL' = 'VENDOR_RISK_CSA',
  'AI_GOVERNANCE_(VENDOR)_FULL' = 'VENDOR_RISK_AI_GOVERNANCE',
  'ESG_(VENDOR)_FULL' = 'VENDOR_RISK_ESG',

  'VENDOR_SAQ_(HC)_FULL' = 'VENDOR_RISK_HQ',
}

export const FrameworkLevelFrontend = {
  HYPERCLEAR: {
    'VENDOR_SAQ_(HC)': ['FULL'],
  },
  INFORMATICA: {
    'VENDOR SAQ': ['TIER 1', 'TIER 2', 'TIER 3'],
    OSAE: ['FULL'],
  },
  HOWDEN: {
    HOWDEN: ['FULL', 'CYBER 360', 'RANSOMWARE', 'OLD'],
  },
  ITC: {
    'VENDOR RISK ITC': ['FULL'],
  },
  CRB: {
    'VENDOR RISK CRB': ['FULL'],
  },
  SSM_HEALTH: {
    'BA HIPAA': ['FULL'],
    'VENDOR RISK SSM HEALTH': ['FULL'],
  },
  ENBD: {
    'VENDOR RISK ENBD': ['FULL'],
    'VENDOR RISK SAQ LIGHT': ['FULL'],
    'VENDOR RISK SOC2': ['FULL'],
  },
  ADNOC: {
    'VENDOR SAQ': ['V2', 'FULL'],
    'VENDOR PRE SAQ': ['V2', 'FULL'],
  },
  MOELIS: {
    'VENDOR RISK MOELIS': ['FULL'],
  },
  REGULAR_INSTANCES: {
    'CENTRALEYES SAQ': ['FULL', 'HIGH', 'MODERATE', 'LIGHT'],
  },
  COMMON: {
    HECVAT: ['FULL', 'ONPREM', 'LIGHT'],
    CSA: ['FULL'],
    ESG: ['FULL'],
    'AI GOVERNANCE': ['FULL'],
  },
};

//* Levels that are used in Vendor Pipe
// TODO Please add LEVEL here if any new framework is added
// TODO Don't duplicate the levels
export const LEVELS = [
  'TIER 1',
  'TIER 2',
  'TIER 3',
  'FULL',
  'LIGHT',
  'LITE',
  'MODERATE',
  'HIGH',
  'ONPREM',
  'CYBER 360',
  'RANSOMWARE',
  'OLD',
  'V2',
];
