export enum LogsKeyEnum {
  YES_ANSWER = 'YES_ANSWER',
  NO_ANSWER = 'NO_ANSWER',
  NA_ANSWER = 'NA_ANSWER',
  PARTIAL_ANSWER = 'PARTIAL_ANSWER',
  REMOVE_ANSWER = 'REMOVE_ANSWER',
  SAVE_COMMENT = 'SAVE_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  SAVE_AUDITOR_COMMENT = 'SAVE_AUDITOR_COMMENT',
  EDIT_AUDITOR_COMMENT = 'EDIT_AUDITOR_COMMENT',
  DELETE_AUDITOR_COMMENT = 'DELETE_AUDITOR_COMMENT',
  MANAGER_APPROVAL = 'MANAGER_APPROVAL',
  MANAGER_DENIAL = 'MANAGER_DENIAL',
  MANAGER_EXCLUDE = 'MANAGER_EXCLUDE',
  ARTIFACT_DOWNLOAD = 'ARTIFACT_DOWNLOAD',
  ARTIFACT_DELETE = 'ARTIFACT_DELETE',
  ARTIFACT_UPLOAD = 'ARTIFACT_UPLOAD',
  STRONG_AUDIT = 'STRONG_AUDIT',
  SATISFACTORY_AUDIT = 'SATISFACTORY_AUDIT',
  NEED_IMPROVEMENT_AUDIT = 'NEED_IMPROVEMENT_AUDIT',
  CONFIRM_REASSEMENT = 'CONFIRM_REASSEMENT',
  WEAK_AUDIT = 'WEAK_AUDIT',
  UPDATE_AUDIT_STATUS = 'UPDATE_AUDIT_STATUS',
  QUESTION_TYPE = 'QUESTION_TYPE',
  ARTIFACT_SETTINGS = 'ARTIFACT_SETTINGS',
  MANAGER_APPROVAL_SETTINGS = 'MANAGER_APPROVAL_SETTINGS',
  UPDATE_VERSION_FREQUENCY = 'UPDATE_VERSION_FREQUENCY',
  ADD_TAG = 'ADD_TAG',
  REMOVED_TAG = 'REMOVED_TAG',
  UPDATE_INTEGRATION_SETTING = 'UPDATE_INTEGRATION_SETTING',
  UPDATE_SMART_MAPPING_SETTING = 'UPDATE_SMART_MAPPING_SETTING',
  SELF_ASSIGNMENT = 'SELF_ASSIGNMENT',
  ADDED_ASSIGNMENT = 'ADDED_ASSIGNMENT',
  REMOVED_ASSIGNMENT = 'REMOVED_ASSIGNMENT',
  CONFIRM_ASSESSMENT = 'CONFIRM_ASSESSMENT',
  UNCONFIRM_ASSESSMENT = 'UNCONFIRM_ASSESSMENT',
  REMOVE_STATUS = 'REMOVE_STATUS',
  UPDATE_INFO_SEC = 'UPDATE_INFO_SEC',
  COMMENTS_SETTINGS = 'COMMENTS_SETTINGS',
  REMEDIATE_TASK = 'REMEDIATE_TASK',
  REMEDIATE_TASK_OWNER = 'REMEDIATE_TASK_OWNER',
  IGNORE_TASK_OWNER = 'IGNORE_TASK_OWNER',
  SAVE_RISK_COMMENT = 'SAVE_RISK_COMMENT',
  EDIT_RISK_COMMENT = 'EDIT_RISK_COMMENT',
  DELETE_RISK_COMMENT = 'DELETE_RISK_COMMENT',
  ESCALATE_RISK = 'ESCALATE_RISK',
  DE_ESCALATE_RISK = 'DE_ESCALATE_RISK',
  ARCHIVE_RISK = 'ARCHIVE_RISK',
  REACTIVATE_RISK = 'REACTIVATE_RISK',
  STATUS_CHANGED_RISK = 'STATUS_CHANGED_RISK',
  DESCRIPTION_CHANGED_RISK = 'DESCRIPTION_CHANGED_RISK',
  TAGS_ADDED_RISK = 'TAGS_ADDED_RISK',
  ASSETS_ADDED_RISK = 'ASSETS_ADDED_RISK',
  OWNER_ASSIGN_RISK = 'OWNER_ASSIGN_RISK',
  OWNER_UN_ASSIGN_RISK = 'OWNER_UN_ASSIGN_RISK',
  IGNORE_TASK = 'IGNORE_TASK',
  ACCEPT_TASK = 'ACCEPT_TASK',
}

export enum LogsTypeEnum {
  QUESTION = 'QUESTION',
  RISK = 'RISK',
  SIGNATURE = 'SIGNATURE',
  ASSESSMENT_LOCKED = 'ASSESSMENT_LOCKED',
  ASSESSMENT_UNLOCK = 'ASSESSMENT_UNLOCK',
}
