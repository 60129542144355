/* eslint-disable @typescript-eslint/no-shadow */
export enum FRAMEWORK_TYPES {
  CERTIFICATES = 'CERTIFICATES',
  REQUIREMENTS = 'REQUIREMENTS',
}

export enum DOMAIN_TABLE_FORTH_PARTY {
  RISK_SCORE = 'riskScore',
  IMPACT = 'impact',
}

export enum IMPACT_CRITICAL {
  CRITICAL = 'CRITICAL',
}

export enum BINARY_EDGE_ENUM {
  FOURTH_PARTY = 'FOURTH_PARTY',
  OTHERS = 'OTHERS',
  DELETE = 'DELETE',
  UPDATED = 'UPDATED',
  ADDED = 'ADDED',
}

export enum REQUIREMENTS {
  DOCUMENTS_PENTEST = 'penetration testing',
  DOCUMENTS_POLICY = 'information security policy',
  SCANS_NETWORK = 'vulnerabilities scans',
  INSURANCE_CYBER = 'cyber insurance',
  NDA = 'non-disclosure agreement',
}

// Coped from StandardEnum
export enum CERTIFICATIONS {
  PCI_DSS = 'PCI DSS',
  // PCI_SAQ_A_EP = 'PCI SAQ A EP',
  // PCI_SAQ_A = 'PCI SAQ A',
  // PCI_SAQ_B = 'PCI SAQ B',
  // PCI_SAQ_B_IP = 'PCI SAQ B IP',
  // PCI_SAQ_C_VT = 'PCI SAQ C VT',
  // PCI_SAQ_C = 'PCI SAQ C',
  // PCI_SAQ_D_MER = 'PCI SAQ D MER',
  // PCI_SAQ_D_SP = 'PCI SAQ D SP',
  // PCI_SAQ_P2PE = 'PCI SAQ P2PE',
  // FFIEC_BASELINE = 'FFIEC BASELINE',
  // FFIEC_EVOLVING = 'FFIEC EVOLVING',
  // FFIEC_INTERMEDIATE = 'FFIEC INTERMEDIATE',
  // FFIEC_ADVANCED = 'FFIEC ADVANCED',
  // FFIEC_INNOVATIVE = 'FFIEC INNOVATIVE',
  // FFIEC_FULL = 'FFIEC FULL',
  GDPR = 'GDPR',
  HIPAA = 'HIPAA',
  ISO_27001 = 'ISO 27001',
  // ISO_27002 = 'ISO 27002',
  CCPA = 'CCPA',
  NIST_800_46 = 'NIST 800 46',
  NIST_800_82 = 'NIST 800 82',
  NIST_800_171 = 'NIST 800 171',
  // NIST_800_171A = 'NIST 800 171A',
  ZERO_TRUST = 'Zero Trust',
  SOC_2 = 'SOC 2',
  // SOC_2_CYGOV = 'SOC 2 CYGOV',
  // AZURE_CLOUD = 'AZURE CLOUD',
  // AWS_CLOUD = 'AWS CLOUD',
  CSA = 'CSA',
  GLBA = 'GLBA',
  NYDFS = 'NYDFS',
  NYDFS_MINIMAL = 'NYDFS MINIMAL',
  CMMC_LEVEL_1 = 'CMMC Level 1',
  CMMC_LEVEL_2 = 'CMMC Level 2',
  CMMC_LEVEL_3 = 'CMMC Level 3',
  FERPA = 'FERPA',
  // PSD_2 = 'PSD 2',
  SOX_COBIT = 'SOX COBIT',
  SOX_NIST = 'SOX NIST',
  // JM_FAMILY = 'JM FAMILY',
  HECVAT = 'HECVAT',
  HECVAT_LIGHT = 'HECVAT LIGHT',
  HECVAT_ONPREM = 'HECVAT ONPREM',
  FEDRAMP_HIGH = 'FEDRAMP HIGH',
  FEDRAMP_MODERATE = 'FEDRAMP MODERATE',
  FEDRAMP_LOW = 'FEDRAMP LOW',
  NERC_V5 = 'NERC V5',
  NIST_800_53 = 'NIST 800 53',
  // ISO_27701 = 'ISO 27701',
  NIST_800_53_R5 = 'NIST 800 53 R5',
  // NY_SHIELD_ACT = 'NY SHIELD ACT',
  // HITRUST = 'HITRUST',
  // ISO_22301 = 'ISO 22301',
  // NEVADA_PRIVACY = 'NEVADA Privacy',
  // ESG = 'ESG',
  // UAE_IA_REGULATION = 'UAE IA',
  // POPIA = 'POPIA',
  // CVS = 'CVS',
  // TSR = 'TSR',
  // PIPL = 'PIPL',
  // OWASPSAMM = 'OWASP-SAMM',
  // SOC_2_V1 = 'SOC 2 V1',
  // CYBER_ESSENTIALS = 'Cyber Essentials',
  // MAINE_PRIVACY_ACT = 'Maine Privacy Act',
  // VIRGINIA_PRIVACY = 'Virginia Privacy',
  // SWIZZNET_SOC_2 = 'Visory SOC2',
}

export class ThirdPartyConstants {
  public static TITLES_WIZARD = {
    'OPEN PORTS': {
      header: [
        { name: 'ID', widthSize: '7.4vw', sort: 'asc', type: 'string' },
        { name: 'IP', widthSize: '10.7vw', sort: 'asc', type: 'number' },
        { name: 'Port', widthSize: '10.7vw', sort: 'asc', type: 'number' },
        { name: 'Type', widthSize: '21vw', type: 'string' },
        { name: 'Created', widthSize: '13vw', sort: 'asc', type: 'number' },
      ],
      mapper: {
        ID: 'tId',
        IP: 'ip',
        Port: 'port',
        Type: 'type',
        Created: 'created',
      },
    },
    CVES: {
      header: [
        { name: 'ID', widthSize: '7.4vw', type: 'string', sort: 'asc' },
        { name: 'IP', widthSize: '7.4vw', type: 'number', sort: 'asc' },
        { name: 'Port', widthSize: '7vw', type: 'number', sort: 'asc' },
        { name: 'CPE', widthSize: '16vw', type: 'string', sort: 'asc' },
        { name: 'CVE', widthSize: '8.4vw', type: 'string', sort: 'asc' },
        { name: 'CVSS', widthSize: '7.4vw', type: 'number', sort: 'asc' },
        { name: 'Created', widthSize: '13vw', sort: 'asc', type: 'number' },
      ],
      mapper: {
        ID: 'tId',
        'Risk Score': 'riskScore',
        Port: 'port',
        CPE: 'cpe',
        CVE: 'cve',
        CVSS: 'cvss',
        IP: 'ip',
        Created: 'created',
      },
    },
    'OTHER FINDINGS': {
      header: [
        { name: 'ID', widthSize: '4.2vw', type: 'string', sort: 'asc' },
        { name: 'Title Finding', widthSize: '7vw', type: 'number', sort: 'asc' },
        { name: 'Tags', widthSize: '5.1vw', type: 'list', sort: 'asc' },
        { name: 'Description', widthSize: '14.2vw', type: 'detailed', sort: 'asc' },
        { name: 'Risk', widthSize: '14.7vw', type: 'detailed', sort: 'asc' },
        { name: 'Risk Score', widthSize: '7.5vw', type: 'score', sort: 'asc' },
        { name: 'Recommendation', widthSize: '13.9vw', type: 'detailed', sort: 'asc' },
        { name: 'Created', widthSize: '5.6vw', type: 'number', sort: 'asc' },
      ],
      mapper: {
        ID: 'tId',
        'Title Finding': 'titleFinding',
        Tags: 'tags',
        Description: 'description',
        Risk: 'risk',
        'Risk Score': 'riskScore',
        Recommendation: 'recommendation',
        Created: 'created',
      },
    },
    // eslint-disable-next-line quote-props
    BREACHES: {
      header: [
        { name: 'ID', widthSize: '4.7vw', type: 'string', sort: 'asc' },
        { name: 'Name', widthSize: '5.7vw', type: 'string', sort: 'asc' },
        { name: 'Year', widthSize: '6.4vw', type: 'number', sort: 'asc' },
        { name: 'Details', widthSize: '22vw', type: 'detailed', sort: 'asc' },
        { name: 'Records', widthSize: '11vw', type: 'record', sort: 'asc' },
        { name: 'Data', widthSize: '22vw', type: 'detailed' },
      ],
      mapper: {
        ID: 'tId',
        Name: 'name',
        Year: 'year',
        Details: 'details',
        Records: 'numbRecords',
        Data: 'data',
      },
    },
    'EMAIL ADDRESSES': {
      header: [
        { name: 'Email', widthSize: '14vw', type: 'string', sort: 'asc' },
        { name: 'Data Breach', widthSize: '32vw', type: 'string' },
      ],
      mapper: {
        Email: 'email',
        'Data Breach': 'dataBreach',
      },
    },
  };

  public static FORTH_PARTY = {
    Risk: {
      header: [
        { name: 'ID', widthSize: '5vw', sort: 'asc', type: 'string' },
        { name: 'Title Finding', widthSize: '13vw', sort: 'asc', type: 'string' },
        { name: 'Tags', widthSize: '8vw', sort: 'asc', type: 'string' },
        { name: 'Description', widthSize: '13vw', type: 'detailed' },
        { name: 'Risk', widthSize: '13vw', sort: 'asc', type: 'string' },
        { name: 'Risk Score', widthSize: '8vw', sort: 'asc', type: 'number' },
        { name: 'Recommendation', widthSize: '13vw', sort: 'asc', type: 'string' },
        { name: 'Created', widthSize: '8vw', sort: 'asc', type: 'string' },
      ],
      mapper: {
        ID: 'tId',
        TITLE: 'title',
        TAGS: 'tags',
        DESCRIPTION: 'description',
        RISK: 'risk',
        RISK_SCORE: 'risk_score',
        RECOMMENDATION: 'recommendation',
        CREATED: 'created',
      },
    },
    Breaches: {
      header: [
        { name: 'ID', widthSize: '8vw', sort: 'asc', type: 'string' },
        { name: 'Name', widthSize: '10vw', sort: 'asc', type: 'string' },
        { name: 'Year', widthSize: '10vw', sort: 'asc', type: 'string' },
        { name: 'Details', widthSize: '21vw', type: 'detailed' },
        { name: 'Number of Records', widthSize: '10vw', sort: 'asc', type: 'string' },
        { name: 'Data', widthSize: '21vw', sort: 'asc', type: 'detailed' },
      ],
      mapper: {
        ID: 'tId',
        Name: 'name',
        Year: 'year',
        Details: 'details',
        'Number of Records': 'numbRecords',
        Data: 'data',
      },
    },
  };
  // Table data types
  public static TableDataTypes = {
    STRING: 'string',
    NUMBER: 'number',
    SCORE: 'score',
    DETAILED: 'detailed',
    DATE: 'date',
    LIST: 'list',
    RECORD: 'record',
  };

  public static TableNamesMapper = {
    'OPEN PORTS': 'openPorts',
    CVES: 'cves',
    'OTHER FINDINGS': 'otherFindings',
    BREACHES: 'breaches',
    'EMAIL ADDRESSES': 'emailAddresses',
  };

  public static BinaryEdgeTableName = {
    'OPEN PORTS': 'Port',
    CVES: 'CVE',
    'OTHER FINDINGS': 'Finding',
    BREACHES: 'Breach',
    'EMAIL ADDRESSES': 'Email',
  };

  public static BinaryEdgeAddType = {
    PORT: 'PORT',
    CVE: 'CVE',
    FINDING: 'FINDING',
    BREACH: 'BREACH',
    EMAIL: 'EMAIL',
  };
  public static AddDataType = {
    PORT: 'openPorts',
    CVE: 'cves',
    FINDING: 'otherFindings',
    BREACH: 'breaches',
    EMAIL: 'emailAddresses',
  };
  public static AddDataTypeMapper = {
    openPorts: 'PORT',
    cves: 'CVE',
    otherFindings: 'FINDING',
    breaches: 'BREACH',
    emailAddresses: 'EMAIL',
  };
  public static ArtifactsStatus = {
    PENDING_REVIEW: 'Pending Review',
    APPROVED: 'Approved',
    DENIED: 'Denied',
    AWAITING_VENDOR_UPLOAD: 'Awaiting Vendor Upload',
    MANUALLY_UPLOADED: 'Manually Uploaded',
    EXCLUDED: 'Excluded',
  };

  public static FrequencyEnum = {
    ANNUAL: 'Annually',
    SEMI_ANNUAL: 'Bi-Annually',
    QUARTERLY: 'Quarterly',
    MANUAL: 'Advance',
  };
  public static FrequencyTextEnum = {
    Annually: 'ANNUAL',
    'Bi-Annually': 'SEMI_ANNUAL',
    Quarterly: 'QUARTERLY',
    Advance: 'MANUAL',
  };

  public static QuestionnaireActionsMapper = {
    RESTART: 'restart-questionnaire',
    ARCHIVE: 'archive-questionnaire',
    DELETE: 'trash-icon-2',
    'restart-questionnaire': 'restart',
    'archive-questionnaire': 'archive',
    'trash-icon-2': 'delete',
  };
}

//* EXPORT VENDOR ENUMS
export enum VENDOR_EXPORT_OPTIONS {
  EXPORT_XLS = 'General Report (XLS)',
  EXPORT_CSV = 'General Report (CSV)',
  EXPORT_CURRENT_XLS = 'Current View (XLS)',
  EXPORT_CURRENT_CSV = 'Current View (CSV)',
  EXECUTIVE_REPORT = 'Executive Summary'
}

//* Report Certifications ENUM
export enum VENDOR_REPORT_CERTIFICATIONS {
  PCI_DSS = 'PCI DSS',
  FFIEC = 'FFIEC',
  GDPR = 'GDPR',
  HIPPA = 'HIPPA',
  ISO27001 = 'ISO27001',
  CCPA = 'CCPA',
}

export enum VendorNotifyTypeEnum {
  NOTIFY_MANAGER_VENDOR_REASSESSMENT = 'NOTIFY_MANAGER_VENDOR_REASSESSMENT',
}
