import {
  GetCustomTaskQuery,
  ModelCommentFilterInput,
  ModelCustomTaskFilterInput,
  TaskAction,
  TaskStatusEnum,
} from 'app/API.service';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { RiskRegisterService } from 'app/risk-register/risk-register.service';
import {
  GetAssessmentQuery,
  GetEntityQuery,
  ModelTaskActionFilterInput,
  RiskAction,
  RoleEnum,
  TaskActionsByAssessmentIdQuery,
} from 'app/API.service';
import { EntityService } from 'app/shared/entity.service';
import { UtilsService } from 'app/shared/utils.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Subject, from } from 'rxjs';
import { UserService } from 'app/shared/user.service';
import { TabOptions } from 'app/shared/enums/remediation-tab-options.enum';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { IntelligenceService } from 'app/third-party/intelligence.service';
import { FrameworkTreeService } from 'app/shared/framework-tree.service';
import { QuestionnaireService } from 'app/questionnaire/questionnaire.service';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { EMAIL_TYPE, CYBER_ARK_FRAMEWORKS } from './remediation.constant';
import { Auth } from 'aws-amplify';
import { CollectionService } from 'app/collection/collection.service';
import { QuestionSettingsEnum } from 'app/shared/enums/question-card.enum';
import { FileService } from 'app/shared/file.service';
import { NOT_MANDATORY_ARTIFACT } from 'app/shared/enums/answer.enum';

@Injectable({
  providedIn: 'root',
})
export class RemediationService implements OnDestroy {
  answers = [];
  managers = [];
  questionSettings = [];
  currentFilter: any;
  nextToken: any = {
    COMPLIANCE: 'start',
    READINESS: 'start',
    INTERNAL_TASKS: 'start',
    MY_TASKS: 'start',
    ALL_TASKS: 'start',
  };
  subscriptions: Subscription[];
  onAssessmentUpdate: Subject<GetAssessmentQuery> = new Subject<GetAssessmentQuery>();
  tabChangeStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  vendorScreen: boolean = false;
  filtersWithAssessmentId = {};
  allPopupQuestions: any[] = [];
  private userMapper: any = {};
  userMapperChanged: EventEmitter<any> = new EventEmitter();
  private allTasks$ = new BehaviorSubject<any[]>([]);
  private readinessTasks$ = new BehaviorSubject<any[]>([]);
  private complianceTasks$ = new BehaviorSubject<any[]>([]);
  private internalTasks$ = new BehaviorSubject<any[]>([]);
  private customTasks$ = new BehaviorSubject<any[]>([]);
  remediationTabsData: any = {
    READINESS: {},
    COMPLIANCE: {},
    INTERNAL_TASKS: {},
    ALL_TASKS: {},
  };
  private acceptedIgnoredTasks = new Map<string, any>();
  private taskSubject = new BehaviorSubject<Map<string, any>>(new Map());
  private remediatedTasks = new Map<string, any>();
  private remediateSubject = new BehaviorSubject<Map<string, any>>(new Map());
  taskCommentsMapper = {};

  private filtersSubject = new BehaviorSubject<any>({
    selectedMaturityList: [],
    selectedFrameworkList: [],
    selectedUsersList: [],
    selectedEntityList: [],
  });

  // Observable to subscribe to filter changes
  filters$ = this.filtersSubject.asObservable();

  // Method to set filters
  setFilters(newFilters: any): void {
    this.filtersSubject.next(newFilters);
  }

  // Method to get the latest filters (as value)
  getFilters(): any {
    return this.filtersSubject.value;
  }
  static sortTodosBySal(controlList) {
    return controlList.sort((a, b) => {
      if (a.impact && b.impact) {
        const salA = RemediationService.salToNum(a.impact);
        const salB = RemediationService.salToNum(b.impact);
        return salA < salB ? -1 : salA > salB ? 1 : 0;
      }
    });
  }

  setAllTasks(tasks: any[]): void {
    this.allTasks$.next(tasks);

    // Categorize tasks
    this.readinessTasks$.next(tasks.filter(task => task.type === 'readiness'));
    this.complianceTasks$.next(tasks.filter(task => task.type === 'compliance'));
    this.internalTasks$.next(tasks.filter(task => task.type === 'internal'));
    this.customTasks$.next(tasks.filter(task => task.type === 'custom'));
  }

  getReadinessTasks(): Observable<any[]> {
    return this.readinessTasks$.asObservable();
  }

  getComplianceTasks(): Observable<any[]> {
    return this.complianceTasks$.asObservable();
  }

  getInternalTasks(): Observable<any[]> {
    return this.internalTasks$.asObservable();
  }

  getAllCustomTasks(): Observable<any[]> {
    return this.customTasks$.asObservable();
  }

  setRemediateFilters(filters: any) {}

  updateTaskUserState(taskId, users: any): void {
    if (!taskId) return;

    if (!this.userMapper[taskId]) {
      this.userMapper[taskId] = users;
    } else {
      this.userMapper[taskId] = [...users];
    }
    this.userMapperChanged.emit({ taskId, users: this.userMapper[taskId] });
  }

  getAssignedUsersByTaskId(taskId) {
    if (this.userMapper[taskId]) {
      return JSON.parse(JSON.stringify(this.userMapper[taskId]));
    }
  }

  setTask(taskId: string, task: any) {
    this.acceptedIgnoredTasks.set(taskId, task);
    this.taskSubject.next(new Map(this.acceptedIgnoredTasks)); // ✅ Notify subscribers
  }

  // ✅ Get task as observable (reactive)
  getTask(taskId: string): Observable<any> {
    return new Observable(observer => {
      this.taskSubject.subscribe(tasks => {
        observer.next(tasks.get(taskId) || null);
      });
    });
  }

  setRemediateTask(taskId: string, task: any) {
    this.remediatedTasks.set(taskId, task);
    this.remediateSubject.next(new Map(this.remediatedTasks)); // ✅ Notify subscribers
  }

  // ✅ Get task as observable (reactive)
  getRemediateTask(taskId: string): Observable<any> {
    return new Observable(observer => {
      this.remediateSubject.subscribe(tasks => {
        observer.next(tasks.get(taskId) || null);
      });
    });
  }

  /**
   * get Comments from the QuestionSet Collection Cache
   * @param questionId
   * @returns
   */
  async getTaskCommentsByQuestionId(question) {
    const questionId = question.questionIds?.length ? question.questionIds[0] : question.id;
    if (this.taskCommentsMapper[questionId]) {
      return this.taskCommentsMapper[questionId];
    }
    const filter: ModelCommentFilterInput = {
      assessmentId: {
        eq: question.assessmentId,
      },
    };
    const taskComments = await this.customApi.CommentsByQuestionId(questionId, null, filter, 1000);
    this.taskCommentsMapper[questionId] = taskComments.items;
    return this.taskCommentsMapper[questionId];
  }
  /**
   * Updated the Existing functionality with new one to update the cache
   * whenever a new comment added or existing comment is updated
   * @param comment
   */
  updateTaskCommentState = (comment, action: 'add' | 'delete' = 'add'): void => {
    const { questionId, id: commentId } = comment;

    if (action === 'add') {
      if (this.taskCommentsMapper[questionId]) {
        this.taskCommentsMapper[questionId].push(comment);
      } else {
        this.taskCommentsMapper[questionId] = [comment];
      }
    } else if (action === 'delete') {
      if (this.taskCommentsMapper[questionId]) {
        this.taskCommentsMapper[questionId] = this.taskCommentsMapper[questionId].filter(
          existingComment => existingComment.id !== commentId
        );
      }
    }
  };

  static salToNum(priority): number {
    let num = 4;
    switch (priority.toLowerCase()) {
      case 'critical':
        num = 1;
        break;
      case 'high':
        num = 2;
        break;
      case 'medium':
        num = 3;
        break;
      case 'low':
        num = 4;
        break;
    }
    return num;
  }

  constructor(
    private riskRegisterService: RiskRegisterService,
    private collectionService: CollectionService,
    private fileService: FileService,
    private entityService: EntityService,
    private router: Router,
    private userService: UserService,
    private intelligenceService: IntelligenceService,
    private questionnaireService: QuestionnaireService,
    private customApi: CUSTOMAPIService,
    private clientLambdaService: ClientLambdaService
  ) {
    // this.riskRegisterService.initializeRiskRegisterService();
    this.vendorScreen = !!this.router.url.includes('third-party');
    from(Auth.currentSession()).subscribe((currentSession: any) => {
      if (!currentSession) {
        return;
      }
      const currentUserToken = currentSession.getAccessToken().getJwtToken();
      this.subscriptions = [this.onUpdateAssessmentListener(currentUserToken)];
    });
  }

  onUpdateAssessmentListener(currentUserToken): any {
    return this.customApi.OnUpdateAssessmentListener(currentUserToken).subscribe({
      next: (res: any) => {
        const updatedAssessment: GetAssessmentQuery = res.value.data.onUpdateAssessment;
        if (UtilsService.isDefined(updatedAssessment)) {
          this.onAssessmentUpdate.next(updatedAssessment);
        }
      },
    });
  }

  async updateRisksOnQuestionAnswered(answeredQuestions: any, riskAction?: RiskAction): Promise<void> {
    const currentUser = await this.userService.getCurrentUser();
    if (
      (currentUser.role === RoleEnum.ADMIN || currentUser.role === RoleEnum.MSSP) &&
      !this.router.url.includes('third-party')
    ) {
      const frameName = FrameworkTreeService.getFrameworkKey();
      const copyAnsweredQuestions = JSON.parse(JSON.stringify(answeredQuestions));
      const filterAnsweredQuestions = copyAnsweredQuestions.filter(question => {
        return question.frameworkName === frameName ? question : 0;
      });
      if (!filterAnsweredQuestions.length) {
        return;
      }
      const groupedQuestions = filterAnsweredQuestions.reduce((result, currentValue) => {
        (result[currentValue.assessmentId] = result[currentValue.assessmentId] || []).push(currentValue);
        return result;
      }, {});
      this.riskRegisterService.deduceAnswerRiskRemediation(currentUser, groupedQuestions, riskAction);
    }
  }

  async populateSubentityList(entityId: string): Promise<GetEntityQuery[]> {
    const childEntityList = await this.entityService.listChildEntitysByParentId(entityId);
    return [...childEntityList];
  }

  resetTokens() {
    this.nextToken = {
      COMPLIANCE: 'start',
      READINESS: 'start',
      INTERNAL_TASKS: 'start',
      MY_TASKS: 'start',
      ALL_TASKS: 'start',
    };
  }

  getTokens() {
    return this.nextToken;
  }

  /**
   * function to get tasks when coming from risk controls
   * @param asId - AssessmentId if sub entity is selected
   * @param controlId
   * @param tab - Selected tab on remediation
   * @returns array of tasks against that control
   */
  async getTaskActionsByAssessmentIdAndQuestionId(assessmentId: string = null, controlId: string, tab: string) {
    const tabName = {
      eq: UtilsService.capitalizeFirstLetter(tab.toLowerCase()),
    };
    try {
      const response = await this.customApi.TaskActionsByAssessmentIdAndTabName(assessmentId, tabName);
      const taskList = response.items.filter(item => {
        return this.matchControl(item.controlsConnected, controlId) || this.vendorScreen;
      });
      return taskList;
    } catch (error) {
      console.log(error);
    }
  }

  async getTaskActionsByAssessmentIdAndTabName(assessmentId: string = null, tab: string) {
    const tabName = {
      eq: UtilsService.capitalizeFirstLetter(tab.toLowerCase()),
    };
    try {
      const response = await this.customApi.TaskActionsByAssessmentIdAndTabName(assessmentId, tabName);
      return response.items;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * function to match the current control
   * @param controls - Array of all controls
   * @param id - Id to be matched
   * @returns - true if found
   */
  matchControl(controls, id): boolean {
    for (const control of controls) {
      return control.id === id;
    }
  }

  async getCustomTasksByControlId(assessmentId: string, customTaskId) {
    const customTaskIdFilter = {
      id: {
        eq: customTaskId,
      },
    };
    // control id will be matched with internal task ids
    try {
      const response = await this.customApi.CustomTasksByAssessmentId(
        assessmentId,
        null,
        customTaskIdFilter,
        1000,
        null
      );
      const tasks = response.items;
      return tasks;
    } catch (error) {
      console.log(error);
    }
  }
  async getCustomTasksById(questionId: string): Promise<GetCustomTaskQuery> {
    try {
      const response = await this.customApi.GetCustomTask(questionId);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async getCustomTasks(assessmentId: string, filter, tabName): Promise<GetCustomTaskQuery[]> {
    let taskList: GetCustomTaskQuery[] = [];
    let toAbort = false;
    let queryFilters: ModelCustomTaskFilterInput = this.createQueryFiltersCustomTask(filter);
    let toFetchByQueryOrFilter: boolean = JSON.stringify(this.currentFilter) !== JSON.stringify(queryFilters);
    this.currentFilter = toFetchByQueryOrFilter ? queryFilters : this.currentFilter;

    if (toFetchByQueryOrFilter) {
      this.nextToken[tabName] = 'start';
    }
    this.subscriptions.push(
      this.tabChangeStatus.subscribe(status => {
        if (status) {
          toAbort = true;
          this.setTabChangeStatus(false);
        }
      })
    );
    if (UtilsService.isDefined(this.nextToken[tabName])) {
      if (UtilsService.isDefined(assessmentId)) {
        while (
          taskList.length <= 10 &&
          this.nextToken[tabName === TabOptions.ALL_TASKS ? `${tabName}#internal` : tabName] !== 'end' &&
          !toAbort
        ) {
          queryFilters = this.createQueryFiltersCustomTask(filter);
          toFetchByQueryOrFilter =
            !!queryFilters && JSON.stringify(this.currentFilter) !== JSON.stringify(queryFilters);
          this.currentFilter = toFetchByQueryOrFilter ? queryFilters : this.currentFilter;
          if (this.currentFilter?.impact) {
            this.currentFilter.impact.eq = this.currentFilter.impact.eq.toUpperCase();
          }
          try {
            const response: any = await this.customApi.CustomTasksByAssessmentId(
              assessmentId,
              null,
              this.currentFilter ? this.currentFilter : null,
              12,
              this.nextToken[tabName] === 'start' ? null : this.nextToken[tabName]
            );
            taskList = taskList.concat(response.items);
            if (tabName === TabOptions.ALL_TASKS) {
              this.nextToken[`${tabName}#internal`] = response.nextToken !== null ? response.nextToken : 'end';
            } else {
              this.nextToken[tabName] = response?.nextToken ? response.nextToken : 'end';
            }
          } catch (error) {
            console.log(error);
          }
        }
      } else if (UtilsService.isDefined(filter)) {
        let toFetchByAssessment = true;
        const tokenCheck =
          tabName === TabOptions.ALL_TASKS ? this.nextToken[`${tabName}#internal`] : this.nextToken[tabName];
        while (taskList.length <= 10 && tokenCheck !== 'end' && !toAbort && toFetchByAssessment) {
          toFetchByAssessment = !filter.or && !!filter[0]?.assessmentId;
          queryFilters = this.createQueryFiltersCustomTask(filter);
          toFetchByQueryOrFilter =
            !!queryFilters && JSON.stringify(this.currentFilter) !== JSON.stringify(queryFilters);
          this.currentFilter = toFetchByQueryOrFilter ? queryFilters : this.currentFilter;
          if (this.currentFilter?.impact) {
            this.currentFilter.impact.eq = this.currentFilter.impact.eq.toUpperCase();
          }
          if (!Array.isArray(filter)) {
            const tempArray = [];

            for (const filterObj in filter) {
              if (filterObj !== 'queries') {
                tempArray.push({ assessmentId: filter[filterObj].assessmentId, queries: filter.queries });
              }
            }
            filter = [...tempArray];
          } else {
            filter = [filter];
          }
          const promiseList = filter.map(async f => {
            const idToFetch = f.assessmentId ? f.assessmentId : f.id.eq;
            if (toFetchByAssessment && this.nextToken[tabName] === 'start') {
              this.nextToken[tabName] = 'in progress';
              if (
                !this.nextToken[
                  `${
                    tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                      ? tabName + '#internal#' + idToFetch
                      : tabName + '#' + idToFetch
                  }`
                ]
              ) {
                this.nextToken[
                  `${
                    tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                      ? tabName + '#internal#' + idToFetch
                      : tabName + '#' + idToFetch
                  }`
                ] = 'start';
              }
            }
            if (toFetchByQueryOrFilter) {
              this.nextToken[tabName] = 'start';
              this.nextToken[
                `${
                  tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                    ? tabName + '#internal#' + idToFetch
                    : tabName + '#' + idToFetch
                }`
              ] = 'start';
            }
            try {
              let tempCurrentFilter;
              if (UtilsService.isDefined(this.currentFilter) && this.currentFilter.searchString) {
                tempCurrentFilter = {
                  name: this.currentFilter.searchString,
                };
              } else if (
                UtilsService.isDefined(this.currentFilter) &&
                this.currentFilter.and &&
                this.currentFilter.and.searchString
              ) {
                // When search and filter both are applied, currentFilter object has 'AND' object
                tempCurrentFilter = {
                  name: this.currentFilter.and.searchString,
                };
              }
              const apiRes = await this.customApi.CustomTasksByAssessmentId(
                idToFetch,
                null,
                // this.currentFilter ? this.currentFilter : null
                // As there is no column of searchString in Custom Task table, so searching on the behalf of name
                tempCurrentFilter ? tempCurrentFilter : this.currentFilter ? this.currentFilter : null,
                12,
                this.nextToken[
                  `${
                    tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                      ? tabName + '#internal#' + idToFetch
                      : tabName + '#' + idToFetch
                  }`
                ] === 'start'
                  ? null
                  : this.nextToken[
                      `${
                        tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                          ? tabName + '#internal#' + idToFetch
                          : tabName + '#' + idToFetch
                      }`
                    ]
              );
              return [apiRes, idToFetch];
            } catch (error) {
              console.log('Exception: ', error);
            }
          });
          let response: any = await Promise.all(promiseList);
          response = response.filter(UtilsService.filterOutUndefinedElements);
          // if (toFetchByAssessment) {
          //   response.forEach(res => {
          //     res[0].items.forEach(item => {
          //       taskList.push(item);
          //     });
          //     this.nextToken[`${tabName}#${res[1]}`] = res[0].nextToken;
          //   });
          //   this.nextToken[tabName] = response.every(res => res[0].nextToken === null) ? 'end' : 'in progress';
          // } else {
          //   taskList = response.map(d => d[0]);
          // }
          if (toFetchByAssessment) {
            toFetchByAssessment = false;
            response.forEach(res => {
              res[0].items.forEach(item => {
                taskList.push(item);
              });
              this.nextToken[
                tabName === TabOptions.ALL_TASKS ? `${tabName}#internal#${res[1]}` : `${tabName}#${res[1]}`
              ] = res[0].nextToken;
            });
            if (tabName !== TabOptions.ALL_TASKS) {
              this.nextToken[tabName] = response.every(res => res[0].nextToken === null) ? 'end' : 'in progress';
            } else {
              this.nextToken[`${tabName}#internal`] = response.every(res => res[0].nextToken === null)
                ? 'end'
                : 'in progress';
            }
          } else {
            taskList = response.map(d => d[0]);
          }
        }
      }
    }
    if (toAbort) {
      taskList = [];
    }
    return taskList;
  }

  // this function gets data from the db, manages infinite scroll based on different tabs and their tokens
  /**
   *
   * @param assessmentId- AssessmentID
   * @param filter - if any filter apllied such as impact
   * @param tabName - Tabname
   * @param isITC - If ITC flag is on, then filter will be applied for scores
   * @returns void
   */
  async getTasks(assessmentId: string, filter, tabName, isITC: boolean = false): Promise<any> {
    try {
      let taskList: TaskActionsByAssessmentIdQuery[] = [];
      let toAbort = false;
      let queryFilters: ModelTaskActionFilterInput = this.createQueryFilters(filter, isITC);
      let toFetchByQueryOrFilter: boolean = JSON.stringify(this.currentFilter) !== JSON.stringify(queryFilters);
      let groupingFrameworks = [];
      let subEntityAllFrameworks = [];
      this.currentFilter = toFetchByQueryOrFilter ? queryFilters : this.currentFilter;
      // Everytime it makes the token start and scrolling was not workin properly
      // if (toFetchByQueryOrFilter) {
      //   this.nextToken[tabName] = 'start';
      // }
      this.subscriptions.push(
        this.tabChangeStatus.subscribe(status => {
          if (status) {
            toAbort = true;
            this.setTabChangeStatus(false);
          }
        })
      );
      if (UtilsService.isDefined(this.nextToken[tabName])) {
        if (UtilsService.isDefined(assessmentId)) {
          const frameworksResp = await this.customApi.StandardFrameworksByAssessmentIdSimplified(assessmentId);
          if (frameworksResp && frameworksResp.items && frameworksResp.items.length) {
            subEntityAllFrameworks = frameworksResp.items;
            groupingFrameworks = subEntityAllFrameworks.filter(framework => framework && framework.not_added);
          }
          let toFetchByAssessment: boolean = true;
          while (
            taskList.length <= 10 &&
            this.nextToken[tabName === TabOptions.ALL_TASKS ? `${tabName}#external` : tabName] !== 'end' &&
            !toAbort &&
            toFetchByAssessment
          ) {
            queryFilters = this.createQueryFilters(filter);
            toFetchByQueryOrFilter =
              !!queryFilters && JSON.stringify(this.currentFilter) !== JSON.stringify(queryFilters);
            this.currentFilter = toFetchByQueryOrFilter ? queryFilters : this.currentFilter;
            let response;
            if (!Array.isArray(filter)) {
              this.filterQueryOnTab(tabName);
              try {
                response = await this.customApi.TaskActionsByAssessmentId(
                  assessmentId,
                  null,
                  this.currentFilter ? this.currentFilter : null,
                  this.currentFilter ? 300 : 12,
                  this.nextToken[tabName] === 'start' ? null : this.nextToken[tabName]
                );
                taskList = taskList.concat(response.items);
                if (tabName === TabOptions.ALL_TASKS) {
                  this.nextToken[`${tabName}#external`] = response.nextToken !== null ? response.nextToken : 'end';
                } else {
                  this.nextToken[tabName] = response?.nextToken ? response.nextToken : 'end';
                }
              } catch (error) {
                console.log(error);
              }
            } else {
              try {
                const promiseList = filter.map(async f => {
                  return await this.customApi.GetTaskAction(f.id.eq);
                });
                response = await Promise.all(promiseList);
                taskList = response;
                toFetchByAssessment = false;
              } catch (error) {}
            }
            this.nextToken[tabName] = response?.nextToken ? response.nextToken : 'end';
          }
        } else if (UtilsService.isDefined(filter)) {
          let toFetchByAssessment = true;
          let tokenCheck =
            tabName === TabOptions.ALL_TASKS ? this.nextToken[`${tabName}#external`] : this.nextToken[tabName];
          while (taskList.length <= 10 && tokenCheck !== 'end' && !toAbort && toFetchByAssessment) {
            toFetchByAssessment = !filter?.or && !!filter[0]?.assessmentId;
            queryFilters = this.createQueryFilters(filter);
            toFetchByQueryOrFilter =
              !!queryFilters && JSON.stringify(this.currentFilter) !== JSON.stringify(queryFilters);
            this.currentFilter = toFetchByQueryOrFilter ? queryFilters : this.currentFilter;
            const promiseList = filter.map(async f => {
              const idToFetch = f.assessmentId ? f.assessmentId : f.id.eq;
              if (toFetchByAssessment && this.nextToken[tabName] === 'start') {
                this.nextToken[tabName] = 'in progress';
                if (
                  !this.nextToken[
                    `${
                      tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                        ? tabName + '#external#' + idToFetch
                        : tabName + '#' + idToFetch
                    }`
                  ]
                ) {
                  this.nextToken[
                    `${
                      tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                        ? tabName + '#external#' + idToFetch
                        : tabName + '#' + idToFetch
                    }`
                  ] = 'start';
                }
              }
              if (toFetchByQueryOrFilter) {
                this.nextToken[tabName] = 'start';
                this.nextToken[
                  `${
                    tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                      ? tabName + '#external#' + idToFetch
                      : tabName + '#' + idToFetch
                  }`
                ] = 'start';
              }
              let apiRes;
              if (toFetchByAssessment) {
                try {
                  if (
                    this.nextToken[
                      `${
                        tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                          ? tabName + '#external#' + idToFetch
                          : tabName + '#' + idToFetch
                      }`
                    ] !== 'end'
                  ) {
                    this.filterQueryOnTab(tabName);
                    apiRes = await this.customApi.TaskActionsByAssessmentId(
                      idToFetch,
                      null,
                      this.currentFilter ? this.currentFilter : null,
                      this.currentFilter ? 300 : 12,
                      this.nextToken[
                        `${
                          tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                            ? tabName + '#external#' + idToFetch
                            : tabName + '#' + idToFetch
                        }`
                      ] === 'start'
                        ? null
                        : this.nextToken[
                            `${
                              tabName.toLowerCase() === TabOptions.ALL_TASKS.toLowerCase()
                                ? tabName + '#external#' + idToFetch
                                : tabName + '#' + idToFetch
                            }`
                          ]
                    );
                  }
                } catch (error) {
                  console.log(error);
                }
              } else {
                try {
                  apiRes = await this.customApi.GetTaskAction(idToFetch);
                } catch (error) {
                  console.log(error);
                }
              }
              return [apiRes, idToFetch];
            });
            try {
              const response = await Promise.all(promiseList);

              if (toFetchByAssessment) {
                toFetchByAssessment = false;
                response.forEach(res => {
                  res[0].items.forEach(item => {
                    taskList.push(item);
                  });
                  this.nextToken[
                    tabName === TabOptions.ALL_TASKS ? `${tabName}#external#${res[1]}` : `${tabName}#${res[1]}`
                  ] = res[0].nextToken;
                });
                if (tabName !== TabOptions.ALL_TASKS) {
                  this.nextToken[tabName] = response.every(res => res[0].nextToken === null) ? 'end' : 'in progress';
                } else {
                  this.nextToken[`${tabName}#external`] = response.every(res => res[0].nextToken === null)
                    ? 'end'
                    : 'in progress';
                }
              } else {
                taskList = response.map(d => d[0]);
              }
              tokenCheck =
                tabName === TabOptions.ALL_TASKS ? this.nextToken[`${tabName}#external`] : this.nextToken[tabName];
              // if (toFetchByAssessment) {
              //   response.forEach(res => {
              //     if (res[0]) {
              //       res[0].items.forEach(item => {
              //         taskList.push(item);
              //       });
              //       this.nextToken[`${tabName}#${res[1]}`] = res[0].nextToken ? res[0].nextToken : 'end';
              //     }
              //   });
              //   this.nextToken[tabName] = response.every(res => !res[0]?.nextToken) ? 'end' : 'in progress';
              // } else {
              //   taskList = response.map(d => d[0]);
              // }
            } catch (error) {}
          }
        }
      }
      if (toAbort) {
        taskList = [];
      }

      // for VENDOR SCREEN
      if (this.vendorScreen && taskList.length) {
        // if framework does not exists in assessment,
        // but task is created against framework then
        // remove it.
        if (subEntityAllFrameworks && subEntityAllFrameworks.length) {
          taskList = taskList.reduce(
            (acc: any, task: any) =>
              subEntityAllFrameworks.find(framework => task.frameworkName === framework.key)
                ? [...acc, task]
                : [...acc],
            []
          );
        }

        // remove grouping tasks from list
        if (groupingFrameworks && groupingFrameworks.length) {
          taskList = taskList.reduce(
            (acc: any, task: any) =>
              !groupingFrameworks.find(gFramework => task.frameworkName === gFramework.key) ? [...acc, task] : [...acc],
            []
          );
        }
      }

      return taskList;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  filterQueryOnTab(tab: string) {
    if (this.currentFilter && (tab === 'COMPLIANCE' || tab === 'READINESS')) {
      const Tab = UtilsService.capitalizeFirstLetter(tab.toLowerCase());
      this.currentFilter = this.currentFilter || {};

      this.currentFilter = Tab
        ? {
            and: [
              this.currentFilter,
              {
                tabName: {
                  contains: Tab,
                },
              },
            ],
          }
        : this.currentFilter;
    }
  }

  /**
   * This function is same as createQueryFilters but it is handling customTasks (Internal tasks tab)
   * As both have different tables, properties and hence filter conditions
   * @param filter
   * @returns Filter object that is compatible with 'ModelCustomTaskFilterInput'
   */
  createQueryFiltersCustomTask(filter): ModelCustomTaskFilterInput {
    let queryFilters;
    if (filter) {
      if (filter.queries && filter.queries.impact && filter.queries.searchString) {
        queryFilters = {
          and: {
            impact: {
              eq: filter.queries.impact.toLowerCase(),
            },
            searchString: {
              contains: filter.queries.searchString.toLowerCase(),
            },
          },
        };
      } else if (filter.queries && filter.queries.impact) {
        queryFilters = {
          impact: {
            eq: filter.queries.impact.toLowerCase(),
          },
        };
      } else if (filter.queries && filter.queries.searchString) {
        queryFilters = {
          searchString: {
            contains: filter.queries.searchString.toLowerCase(),
          },
        };
      }
      if (filter.queries && filter.queries.status) {
        queryFilters = {
          ...queryFilters,
          status: filter.queries.status,
        };
      }
    }

    return queryFilters;
  }

  /**
   *
   * @param filter - any filter like impact or search
   * @param isITC - If ITC, then apply filter for scores
   * @returns - filter objects
   */
  createQueryFilters(filter, isITC: boolean = false): ModelTaskActionFilterInput {
    let queryFilters;
    if (filter) {
      if (filter.queries && filter.queries.impact && filter.queries.searchString) {
        queryFilters = {
          and: [
            {
              or: [
                { impact: { contains: filter.queries.impact.toLowerCase() } },
                { impact: { contains: UtilsService.capitalizeFirstLetter(filter.queries.impact.toLowerCase()) } },
              ],
            },
            {
              searchString: {
                contains: filter.queries.searchString.toLowerCase(),
              },
            },
          ],
        };
      } else if (filter.queries && filter.queries.impact) {
        queryFilters = {
          or: [
            { impact: { contains: filter.queries.impact.toLowerCase() } },
            { impact: { contains: filter.queries.impact.toUpperCase() } }, //* need to add with capital filter
            { impact: { contains: UtilsService.capitalizeFirstLetter(filter.queries.impact.toLowerCase()) } },
          ],
        };
      } else if (filter.queries && filter.queries.searchString) {
        queryFilters = {
          searchString: {
            contains: filter.queries.searchString.toLowerCase(),
          },
        };
      }
      // To get all tasks which do not have score 0 or 10, ie: non remediated tasks only.
      if (isITC) {
        queryFilters = {
          and: [
            {
              score: {
                ne: 0,
              },
            },
            {
              score: {
                ne: 10,
              },
            },
          ],
        };
      }
    }
    if (queryFilters) {
      return (queryFilters = { and: [{ ...queryFilters }, { managerAction: { ne: 'IGNORED' } }] });
    } else {
      return (queryFilters = {
        or: [
          { status: { eq: TaskStatusEnum.IGNORED } },
          { status: { eq: TaskStatusEnum.REMEDIATED } },
          { status: { eq: TaskStatusEnum.ACCEPTED } },
          { status: { eq: TaskStatusEnum.OPEN } },
        ],
      });
    }
  }

  async assignUser(user, taskType): Promise<any> {
    try {
      if (taskType === 'CustomTask') {
        const { taskId: targetId } = user;
        delete user.taskId;
        return await this.customApi.CreateCustomTaskAssignment({ ...user, targetId }, null);
      }
      return await this.customApi.CreateUsersAssignmentMapper(user, null);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * *Function sends email when new task is assigned to user
   * @param user user data
   * @param rootEntityId root entity id
   * @param subEntityId sub entity id
   */
  async sendAssignUserEmailNotification(user, rootEntityId, subEntityId) {
    const json = {
      type: EMAIL_TYPE.NOTIFY_USER_NEW_TASK_ASSIGN,
      user,
      rootEntityId,
      subEntityId,
    };
    try {
      await this.clientLambdaService.invokeLambda('sendNotification', json);
    } catch (error) {
      console.error(
        `Issue While Sending Notification Email${EMAIL_TYPE.NOTIFY_USER_NEW_TASK_ASSIGN}` + JSON.stringify(error)
      );
    }
  }

  async unAssignUser(id, assessmentId, taskType) {
    try {
      if (taskType === 'CustomTask') {
        return await this.customApi.DeleteCustomTaskAssignment(id, { assessmentId: { eq: assessmentId } });
      }
      return await this.customApi.DeleteUsersAssignmentMapper(id, { assessmentId: { eq: assessmentId } });
    } catch (error) {
      console.log(error);
    }
  }

  async updateAnswer(questionId: string, assessmentId: string) {
    try {
      const answer = await this.customApi.AnswersByAssessmentIdAndQuestionId(assessmentId, { eq: questionId });
      const item = answer.items[0];
      const toUpdate = {
        id: item.id,
        answer: 10,
        origin: null,
      };
      const updatedAnswer = await this.customApi.UpdateAnswer(toUpdate, { assessmentId: { eq: assessmentId } });
      return updatedAnswer;
    } catch (error) {
      console.log(error);
    }
  }

  async fetchAnswers(assessmentId: string, questionId: string): Promise<any> {
    try {
      const answer = await this.customApi.AnswersByAssessmentIdAndQuestionId(
        assessmentId,
        {
          eq: questionId,
        },
        null,
        null,
        1000,
        null
      );
      return answer.items;
    } catch (error) {
      console.log(error);
    }
  }

  async setAnswers(questions, isBreakDown = false): Promise<any> {
    let answerPromises = [];
    if (!isBreakDown) {
      answerPromises = questions.flatMap(question => {
        return question.questionIds.map(qId => {
          return this.fetchAnswers(question.assessmentId, qId);
        });
      });

      return await Promise.all(answerPromises);
    } else {
      answerPromises = questions.map(question => {
        return this.fetchAnswers(question.assessmentIds[0], question.id);
      });

      return await Promise.all(answerPromises);
    }
  }

  async fetchComments(question: any, isRemediate: boolean = true): Promise<any> {
    try {
      let comments = [];
      const nextToken = null;
      do {
        if (isRemediate) {
          const questionId = question?.questionIds?.length ? question.questionIds[0] : question?.id;
          const assessmentId = question?.assessmentIds?.length ? question.assessmentIds[0] : question?.assessmentIds;
          const filter: ModelCommentFilterInput = {
            assessmentId: {
              eq: assessmentId,
            },
          };
          const response = await this.customApi.CommentsByQuestionId(questionId, null, filter, 1000, nextToken);
          comments = comments.concat(response.items as any);
        } else {
          // in case of breakdown screen
          const response = await this.customApi.CommentByAssessmentId(question, null, null, 1000, nextToken);
          comments = comments.concat(response.items as any);
        }
      } while (nextToken);
      return comments;
    } catch (error) {
      console.log(error);
    }
  }

  async fetchAssignment(question: any): Promise<any> {
    const assignments = await this.questionnaireService.fetchAssessmentsAssignments(question?.assessmentIds);
    const questionAssignments = assignments.filter(assignment => {
      return question.left >= assignment.left && question.right <= assignment.right;
    });
    return questionAssignments;
  }

  async filterComments(questions: string[]): Promise<any> {
    try {
      const commentsPromises = questions.map(question => this.fetchComments(question));
      const commentsArr = await Promise.all(commentsPromises);
      return commentsArr;
    } catch (error) {
      console.log(error);
    }
  }

  async setManagers(assessmentIds: string[]): Promise<any> {
    try {
      let managers = [];
      for (const assessmentId of assessmentIds) {
        const nextToken = null;
        do {
          const response = await this.customApi.FrameworkManagersByAssessmentId(
            assessmentId,
            null,
            null,
            1000,
            nextToken
          );
          managers = managers.concat(response.items as any);
        } while (nextToken);
      }
      this.managers = managers;
      return this.managers;
    } catch (error) {
      console.log(error);
    }
  }

  async fetchQuestionSettings(assessmentIds: string[]): Promise<any> {
    try {
      let settings = [];
      for (const assessmentId of assessmentIds) {
        const nextToken = null;
        do {
          const response = await this.customApi.QuestionSettingsByAssessmentId(
            assessmentId,
            null,
            null,
            1000,
            nextToken
          );
          settings = settings.concat(response.items as any);
        } while (nextToken);
      }
      this.questionSettings = settings;
      return this.questionSettings;
    } catch (error) {
      console.log(error);
    }
  }

  async filteredTasks(questions): Promise<any> {
    try {
      const AssessmentIDs = [];
      const subEntitiesPromises = [];
      const assignmentsPromises = [];
      const answerPromises = [];
      const commentsPromises = [];
      const frameworkSettingsPromise = [];
      const frameworkIds = [];
      this.filtersWithAssessmentId = {};

      questions = questions.map(q => {
        return {
          ...q,
          managerAnswer: [],
          comments: [],
          settings: [],
          answers: [],
          assessmentIds: [q.assessmentId],
        };
      });

      questions.map((ques: any) => {
        if (!this.filtersWithAssessmentId[ques.assessmentId]) {
          AssessmentIDs.push(ques.assessmentId);
          subEntitiesPromises.push(this.customApi.GetQuestionSettingsFromEntityByAssessmentId(ques.assessmentId));
          if (frameworkIds.findIndex(item => item === ques.assessmentId + '#' + ques.frameworkName) === -1) {
            frameworkIds.push(ques.assessmentId + '#' + ques.frameworkName);
          }

          this.filtersWithAssessmentId[ques.assessmentId] = {
            questionIds: { or: [] },
            targetIds: { or: [] },
          };
        }

        this.filtersWithAssessmentId[ques.assessmentId].questionIds.or.push({
          questionId: {
            eq: ques.questionIds[0],
          },
        });

        const targetId = ques?.assessmentId + '#' + ques?.questionIds[0];
        this.filtersWithAssessmentId[ques.assessmentId].targetIds.or.push({
          targetId: {
            eq: targetId,
          },
        });
      });

      frameworkIds.forEach(fid => {
        frameworkSettingsPromise.push(this.getFrameworkSettings(fid));
      });

      Object.keys(this.filtersWithAssessmentId).forEach(assessId => {
        const chunkValue = 100;

        for (let i = 0; i < this.filtersWithAssessmentId[assessId].questionIds.or.length; i += chunkValue) {
          const quesIds = this.filtersWithAssessmentId[assessId].questionIds.or.slice(i, i + chunkValue);
          const filter = {
            or: quesIds,
          };

          assignmentsPromises.push(this.questionnaireService.getAssignmentsByAssessmentId(assessId));
          answerPromises.push(this.fetchAllAnswers(assessId, filter, 1000));
          commentsPromises.push(this.fetchAllComments(assessId, filter, 1000));
        }
      });

      const [comments, managers, settings, allSubEntities, allAssignments, answers, frameworkSettings] =
        await Promise.all([
          Promise.all(commentsPromises),
          this.setManagers(AssessmentIDs),
          this.fetchQuestionSettings(AssessmentIDs),
          Promise.all(subEntitiesPromises),
          Promise.all(assignmentsPromises),
          Promise.all(answerPromises),
          Promise.all(frameworkSettingsPromise),
        ]);

      const finalAnswers = answers.flatMap(ans => ans);
      const finalComments = comments.flatMap(comment => comment);
      const mappedAssignments = allAssignments.flatMap(assignment => assignment);

      const subEntitiesByAssessmentId = allSubEntities.reduce((acc, sub) => {
        acc[sub.items[0].activeAssessmentId] = sub.items[0];
        return acc;
      }, {});

      const assignmentsByAsessmentId = {};

      mappedAssignments.map(assignment => {
        if (!assignmentsByAsessmentId[assignment.assessmentId]) {
          assignmentsByAsessmentId[assignment.assessmentId] = [];
        }

        assignmentsByAsessmentId[assignment.assessmentId].push(assignment);
      });

      if (answers && comments && managers && settings) {
        questions.forEach(question => {
          finalAnswers.forEach(ans => {
            managers.forEach(manager => {
              if (manager.managerId === ans.userId && !question.managerAnswer.some(m => m.userId === ans.userId)) {
                question.managerAnswer.push(ans);
              }
            });
            if (question.questionIds[0] === ans.questionId && ans.isFromTaskOwner) {
              question.answers.push(ans);
              question.left = ans.left;
              question.right = ans.right;
            } else if (
              question.questionIds[0] === ans.questionId &&
              !question.answers.some(m => m.userId === ans.userId && !m.isFromTaskOwner)
            ) {
              question.answers.push(ans);
              question.left = ans.left;
              question.right = ans.right;
            }
          });

          finalComments.forEach(comment => {
            if (comment.assessmentId === question.assessmentId && comment.questionId === question.questionIds[0]) {
              question.comments.push(comment);
            }
          });

          this.questionSettings.forEach(setting => {
            if (setting.assessmentId === question.assessmentId && setting.questionId === question.questionIds[0]) {
              question.settings.push(setting);
            }
          });

          if (question.answers.length) {
            question.answers.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
          }
          const fSettingsIdx = frameworkSettings.findIndex(
            item => item.id === question.assessmentId + '#' + question.frameworkName
          );

          if (question.settings && question.settings.length === 0 && fSettingsIdx > -1) {
            question.settings.push(frameworkSettings[fSettingsIdx]);
          }

          if (question.settings && question.settings.length > 0) {
            if (fSettingsIdx > -1) {
              const frmkSettings = frameworkSettings[fSettingsIdx];
              this.syncQuestionSettingsWithFrwkSettings(question, frmkSettings);
            }
          }

          const subEntity = subEntitiesByAssessmentId[question.assessmentId];
          if (subEntity && subEntity.defaultQuestionSettings) {
            const subEntityQuestionSettings = subEntity.defaultQuestionSettings;
            // Replace questions settings with entity question settings if found
            if (!question.settings || question.settings.length === 0) {
              const defaultQuestionConfig = {
                __typename: 'QuestionSettings',
                assessmentId: question.assessmentId,
                questionId: question.id,
              };
              const questionSettings = { ...subEntityQuestionSettings, ...defaultQuestionConfig };
              question.settings.push(questionSettings);
            }
          }

          question.assignments = assignmentsByAsessmentId[question.assessmentId].filter(assignment => {
            return question.left >= assignment.left && question.right <= assignment.right;
          });
        });
      }

      this.allPopupQuestions = questions;
      return questions;
    } catch (error) {
      console.log('Failed to get data ===============>', error);
    }
  }

  syncQuestionSettingsWithFrwkSettings(question, frmkSettings) {
    const answers_Options = this.fileService.getFrameworkAnswersOptions(question?.frameworkName || '');
    if (
      question.settings[0]?.isCommentsMandatory &&
      (!UtilsService.isDefined(question.settings[0]?.requiredCommentAnswers) ||
        question.settings[0]?.requiredCommentAnswers === 'undefined')
    ) {
      if (frmkSettings.suggestedComments === QuestionSettingsEnum.SUGGESTED) {
        Object.keys(answers_Options).forEach(opt => {
          if (answers_Options[opt]?.comment) {
            question.settings[0].requiredCommentAnswers = question.settings[0]?.requiredCommentAnswers
              ? question.settings[0]?.requiredCommentAnswers + ',' + answers_Options[opt]?.questionOptionOrder
              : '' + answers_Options[opt]?.questionOptionOrder;
          }
        });
      } else if (frmkSettings.suggestedComments === QuestionSettingsEnum.ALL) {
        Object.keys(answers_Options).forEach(opt => {
          question.settings.requiredCommentAnswers = question.settings[0].requiredCommentAnswers
            ? question.settings[0].requiredCommentAnswers + ',' + answers_Options[opt]?.questionOptionOrder
            : '' + answers_Options[opt]?.questionOptionOrder;
        });
      }
    }

    if (
      question.settings[0]?.isArtifactsMandatory &&
      (!UtilsService.isDefined(question.settings[0]?.suggestedArtifactAnswers) ||
        question.settings[0]?.suggestedArtifactAnswers === 'undefined')
    ) {
      if (frmkSettings.suggestedComments === QuestionSettingsEnum.SUGGESTED) {
        Object.keys(answers_Options).forEach(opt => {
          if (answers_Options[opt]?.artifact) {
            question.settings[0].suggestedArtifactAnswers = question.settings[0].suggestedArtifactAnswers
              ? question.settings[0].suggestedArtifactAnswers + ',' + answers_Options[opt]?.questionOptionOrder
              : '' + answers_Options[opt]?.questionOptionOrder;
          }
        });
      } else if (frmkSettings.suggestedComments === QuestionSettingsEnum.ALL) {
        Object.keys(answers_Options).forEach(opt => {
          if (!NOT_MANDATORY_ARTIFACT.includes(answers_Options[opt].name)) {
            question.settings[0].suggestedArtifactAnswers = question.settings[0].suggestedArtifactAnswers
              ? question.settings[0].suggestedArtifactAnswers + ',' + answers_Options[opt]?.questionOptionOrder
              : '' + answers_Options[opt]?.questionOptionOrder;
          }
        });
      }
    }
  }

  async getFrameworkSettings(id) {
    const currentFramework = await this.collectionService.getAssessmentStandardFramework(id);
    const frameworkSettings = currentFramework?.defaultQuestionSettings;
    return {
      ...frameworkSettings,
      id: currentFramework?.id,
      suggestedArtifact: currentFramework?.suggestedArtifact,
      suggestedComments: currentFramework?.suggestedComments,
      isCollaborative:
        frameworkSettings && Object.hasOwn(frameworkSettings, 'isCollaborative')
          ? frameworkSettings.isCollaborative
          : true,
    };
  }

  async fetchAllAnswers(assessmentId, filter, limit) {
    let answers = [];
    let nextToken = null;

    do {
      const response = await this.customApi.AnswersByAssessmentId(assessmentId, null, filter, limit, nextToken);
      answers = answers.concat(response.items);
      nextToken = response.nextToken;
    } while (nextToken);

    return answers;
  }

  async fetchAllComments(assessmentId, filter, limit) {
    let comments = [];
    let nextToken = null;

    do {
      const response = await this.customApi.CommentByAssessmentId(assessmentId, null, filter, limit, nextToken);
      comments = comments.concat(response.items);
      nextToken = response.nextToken;
    } while (nextToken);

    return comments;
  }

  fetchLogsAndVersionsForRemediation() {
    const filters = this.filtersWithAssessmentId;

    Object.keys(filters).map(assessId => {
      const chunkValue = 100;

      for (let i = 0; i < this.filtersWithAssessmentId[assessId].targetIds.or.length; i += chunkValue) {
        const targetIds = this.filtersWithAssessmentId[assessId].targetIds.or.slice(i, i + chunkValue);
        const filter = {
          or: targetIds,
        };

        from(this.customApi.VersionsByAssessmentId(assessId, null, filter, 1000, null)).subscribe(versions => {
          const versionsByTargetId = this.createHahMapByTargetId(versions.items);
          this.addDataInQuestions('versions', versionsByTargetId);
        });

        from(this.customApi.LogsByAssessmentId(assessId, null, filter, 1000, null)).subscribe(logs => {
          const logsByTargetId = this.createHahMapByTargetId(logs.items);
          this.addDataInQuestions('logs', logsByTargetId);
        });
      }
    });
  }

  addDataInQuestions(source, apiResponse) {
    this.allPopupQuestions.map(ques => {
      const targetId = ques?.assessmentId + '#' + ques?.questionIds[0];
      if (source === 'logs' && apiResponse[targetId]) {
        this.sortLogs(apiResponse[targetId]);
        ques.logs = apiResponse[targetId];
        ques.logsStartIndex = 0;
        ques.logsLastIndex = 2;
        ques.questionLogs = ques?.logs?.slice(ques.logsStartIndex, ques.logsLastIndex);
      } else if (source === 'versions' && apiResponse[targetId]) {
        const currentQuestionVersions = apiResponse[targetId];
        const questionVersions = [];
        currentQuestionVersions.forEach(version => {
          const date = new Date(version.createdAt);
          questionVersions.push({
            date,
            selected: false,
            createdAt: version.createdAt,
            data: JSON.parse(version.data),
          });
        });
        this.sortDates(questionVersions);

        ques.questionVersions = questionVersions;
      }
    });
  }

  createHahMapByTargetId(data) {
    const dataByTargetId = {};
    data.map(version => {
      if (!dataByTargetId[version.targetId]) {
        dataByTargetId[version.targetId] = [version];
      } else {
        dataByTargetId[version.targetId].push(version);
      }
    });

    return dataByTargetId;
  }
  fetchLogsAndVersionsForBreakdown(questions) {
    const targetId = `${questions[0].assessmentIds[0]}#${questions[0].id}`;
    const filter = {
      assessmentId: {
        eq: questions[0].assessmentIds[0],
      },
    };
    from(this.customApi.VersionsByTargetId(targetId, null, filter, 1000, null)).subscribe(versions => {
      const questionVersions = [];
      versions.items.forEach(version => {
        const date = new Date(version.createdAt);
        questionVersions.push({
          date,
          selected: false,
          createdAt: version.createdAt,
          data: JSON.parse(version.data),
        });
      });
      this.sortDates(questionVersions);
      questions[0].questionVersions = questionVersions;
    });

    from(this.customApi.LogsByTargetId(targetId, null, filter, 1000, null)).subscribe(logs => {
      this.sortLogs(logs.items);
      questions[0].logs = logs.items;
      questions[0].logsStartIndex = 0;
      questions[0].logsLastIndex = 2;
      questions[0].questionLogs = questions[0]?.logs?.slice(questions[0].logsStartIndex, questions[0].logsLastIndex);
    });
  }

  sortLogs(array) {
    array.sort(function (a, b) {
      const date1 = new Date(a.createdAt).valueOf();
      const date2 = new Date(b.createdAt).valueOf();
      return date2 - date1;
    });
  }

  sortDates(array) {
    array.sort(function (a, b) {
      const date1 = new Date(a.createdAt).valueOf();
      const date2 = new Date(b.createdAt).valueOf();
      return date2 - date1;
    });
  }

  // update this function to show the ignored task in remediation and previous function is commented
  removeRemediatedTasks(tasksData) {
    try {
      if (UtilsService.isBnB) {
        return this.removeRemediatedTasksBNB(tasksData);
      } else if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
        return tasksData.filter(
          task =>
            task.status === 'IGNORED' ||
            task.status === 'REMEDIATED' ||
            (task.score !== undefined ? task.score < 10 : task.status === 'OPEN')
        );
      } else {
        return tasksData.filter(task => {
          //* CyberArk Frameworks have score range from 0 to 8
          if (CYBER_ARK_FRAMEWORKS.includes(task.frameworkName)) {
            return (
              task.status === 'IGNORED' ||
              task.status === 'REMEDIATED' ||
              (task.score !== undefined ? task.score >= 0 && task.score < 9 : task.status === 'OPEN')
            );
          } else {
            //* Normal Frameworks have score range from 0 to 9
            return (
              task.status === 'IGNORED' ||
              task.status === 'REMEDIATED' ||
              (task.score !== undefined ? task.score >= 0 && task.score < 10 : task.status === 'OPEN')
            );
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // removeRemediatedTasks(tasksData) {
  //   try {
  //     if (UtilsService.isBnB) {
  //       return this.removeRemediatedTasksBNB(tasksData);
  //     } else if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
  //       return tasksData.filter(task =>
  //         task.score ? task.score && task.score < 10 : task.status && task.status === 'OPEN'
  //       );
  //     } else {
  //       return tasksData.filter(task => {
  //         //* CyberArk Frameworks have score range from 0 to 8
  //         if (CYBER_ARK_FRAMEWORKS.includes(task.frameworkName)) {
  //           return task.score !== undefined ? task.score >= 0 && task.score < 9 : task.status && task.status === 'OPEN';
  //         } else {
  //           //* Normal Frameworks have score range from 0 to 9
  //           return task.score !== undefined
  //             ? task.score >= 0 && task.score < 10
  //             : task.status && task.status === 'OPEN';
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  /**
   * Function to remove remediated task but 0 is No in BNB case so no need to remove it
   * @param tasksData
   * @returns non remediated tasks
   */
  removeRemediatedTasksBNB(tasksData) {
    try {
      return tasksData.filter(task => (UtilsService.isDefined(task.score) ? task.score < 4 : task.status === 'OPEN'));
    } catch (error) {
      console.log(error);
    }
  }

  async fetchQuestionSettingsbyQuestionId(questionId: string, assessmentId: string): Promise<any> {
    try {
      let settings = [];
      const nextToken = null;
      const filter = {
        assessmentId: {
          eq: assessmentId,
        },
      };
      do {
        const response = await this.customApi.QuestionSettingsByQuestionId(questionId, null, filter, 1000, nextToken);
        settings = settings.concat(response.items as any);
      } while (nextToken);
      this.questionSettings = settings;
      return this.questionSettings;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * For only breakdown related question settings.
   * @param questions
   * @returns
   */
  async questionSettingsForBreakDown(questions): Promise<any> {
    try {
      for (const question of questions) {
        const settings = await this.fetchQuestionSettingsbyQuestionId(question?.id, question?.assessmentIds[0]);
        const subEntity = await this.customApi.EntityByAssessmentId(question?.assessmentIds[0]);
        const subEntityQuestionSettings = subEntity.items[0].defaultQuestionSettings;
        question.settings = [];
        if (settings) {
          settings.forEach(setting => {
            if (setting.assessmentId === question.assessmentIds[0] && setting.questionId === question.id) {
              question.settings.push(setting);
            }
          });
          const frmkSettings = await this.getFrameworkSettings(
            question.assessmentIds[0] + '#' + question.frameworkName
          );
          this.syncQuestionSettingsWithFrwkSettings(question, frmkSettings);
        }
        if (subEntityQuestionSettings && !settings.length) {
          if (!question.settings || question.settings.length === 0) {
            const defaultQuestionConfig = {
              __typename: 'QuestionSettings',
              assessmentId: subEntity.items[0].activeAssessmentId,
              questionId: question.id,
            };
            const questionSettings = { ...subEntityQuestionSettings, ...defaultQuestionConfig };
            question.settings.push(questionSettings);
          }
        }
      }
      return questions;
    } catch (error) {
      console.log(error);
      return questions;
    }
  }

  setTabChangeStatus(status) {
    this.tabChangeStatus.next(status);
  }

  async updateRisksForClosedManualTask(tasksArray, entityId: string): Promise<void> {
    const taskObjList = {};
    tasksArray.forEach(tsk => {
      taskObjList[tsk.id] = { assessmentId: tsk.assessmentId };
    });
    await this.riskRegisterService.updateRiskManualTaskStatus(taskObjList, entityId);
  }

  /**
   * Function create Filter object for getting tasks based on Impact
   * Some questions have impact as lowercase and some have Captitalized first letter
   * @param impact - Current Impact
   * @returns - Filter Object with OR property
   */
  createFilter(impact): any {
    const filter = [
      { impact: { eq: impact.toLowerCase() } },
      { impact: { contains: UtilsService.capitalizeFirstLetter(impact.toLowerCase()) } },
    ];
    return filter;
  }

  async getTopThreeRisks(entityId: string): Promise<TaskAction[]> {
    const subentities = await this.populateSubentityList(entityId);
    let assessmentsInEntity;
    const risks = [];
    const filter = {
      or: this.createFilter(ImpactTextEnum.CRITICAL),
      tabName: {
        eq: 'Readiness',
      },
      score: {
        lt: 10,
        gt: 0,
      },
    };
    if (subentities && subentities.length) {
      assessmentsInEntity = subentities.map(subentity => subentity.activeAssessmentId);
      let gotTopThree = false;
      while (!gotTopThree) {
        for (const assessmentId of assessmentsInEntity) {
          const response = await this.customApi.TaskActionsByAssessmentId(assessmentId, null, filter, 1000, null);
          risks.push(...response.items);
        }
        if (
          (risks && risks.length && risks.length >= 3) ||
          !filter.or ||
          filter.or[0]?.impact?.eq === ImpactTextEnum.LOW
        ) {
          gotTopThree = true;
          break;
        } else if (filter.or[0]?.impact?.eq) {
          switch (filter.or[0].impact.eq) {
            case ImpactTextEnum.CRITICAL:
              filter.or = this.createFilter(ImpactTextEnum.HIGH);
              break;
            case ImpactTextEnum.HIGH:
              filter.or = this.createFilter(ImpactTextEnum.MEDIUM);
              break;
            case ImpactTextEnum.MEDIUM:
              filter.or = this.createFilter(ImpactTextEnum.LOW);
              break;
            default:
              filter.or = null;
          }
        }
      }

      const impactPriority = {
        [ImpactTextEnum.CRITICAL]: 4,
        [ImpactTextEnum.HIGH]: 3,
        [ImpactTextEnum.MEDIUM]: 2,
        [ImpactTextEnum.LOW]: 1,
      };

      risks.sort((a, b) => impactPriority[b.impact] - impactPriority[a.impact]).length = 3;
    }
    return risks;
  }

  async getIntelligenceData(vendor) {
    const { allS3Data, combinedData } = await this.intelligenceService.populateIntelligenceDataFromS3(vendor);
    return { allS3Data, combinedData };
  }
  ngOnDestroy() {
    this.subscriptions.forEach(listener => listener.unsubscribe());
  }
}
