export class CollectionConstant {
  public static LABELS: any = {
    allFrameworks: 'All Frameworks',
    managerTilePlural: 'The managers that are assigned to this framework are : ',
    managerTileSingular: 'The manager that is assigned to this framework is : ',
    all: 'All',
  };
  public static EXPORT_OPTIONS: any = {
    EXPORT: 'General Report (XLS)',
    EXPORT_CSV: 'General Report (CSV)',
    DOWNLOAD: 'All Artifacts (ZIP)',
    EXPORT_ACTIVITY_LOGS: 'Activity Logs (CSV)',
    DOWNLOAD_AUDIT_REPORT: 'Internal Audit Report',
    DOWNLOAD_BEAZLEY_REPORT: 'Beazley Report',
    DOWNLOAD_MID_MARKET_REPORT: 'MidMarket Report',
    DOWNLOAD_BEAZLEY_IA_OT: 'Insurance Application with OT',
    DOWNLOAD_B_APP_RSWOT_TEO: 'Insurance Application with OT & TEO',
    DOWNLOAD_BEAZLEY_TEO: 'Insurance Application + TEO',
    DOWNLOAD_NISD_REPORT: 'NIS-D Report',
    DOWNLOAD_CMMC_REPORT: 'CMMC SSP (DOCX)',
    DOWNLOAD_CMMC_POAM_DOCX_REPORT: 'CMMC POA&M (DOCX)',
    DOWNLOAD_CMMC_POAM_XLSX_REPORT: 'CMMC POA&M (XLSX)',
    DOWNLOAD_REPORT: 'PCI Report (DOC)',
  };
  public static HITRUST_POINTS: any = {
    POLICY: 15,
    PROCESS: 20,
    IMPLEMENTED: 40,
    MEASURED: 10,
    MANAGED: 15,
  };
}

export enum FRAMEWORKS_WITH_DISABLED_SETTINGS {
  DOWNLOAD_BEAZLEY_REPORT = 'Download Beazley Report',
  DOWNLOAD_BEAZLEY_IA_OT = 'Download Insurance Application with OT',
  DOWNLOAD_NISD_REPORT = 'Download NIS-D Report',
  DOWNLOAD_REPORT = 'Download Report',
}

export enum INSURANCE_APP_FRAMEWORKS {
  BEAZLEY = 'BEAZLEY',
  InsuranceWithOT = 'B_APP_RSWOT',
  InsuranceWithOTAndTEO = 'B_APP_RSWOT_TEO',
  BEAZLEY_TEO = 'BEAZLEY_TEO',
}

export enum MID_MARKET_FRAMEWORKS {
  MID_MARKET_BASIC = 'MID_MARKET_BASIC', //* Mid Market Application BASIC
  MID_MARKET_BASIC_OT = 'MID_MARKET_BASIC_OT', //* Mid Market Application + OT
  MID_MARKET_BASIC_TECH = 'MID_MARKET_BASIC_TECH', //* Mid Market Application + Tech E&O
  MID_MARKET_APPLICATION = 'MID_MARKET_APPLICATION', // * Mid Market Application + OT + Tech E&O [FULL]
}

export enum PCI_FRAMEWORKS {
  PCI_DSS_4_0 = 'PCI_DSS_4.0',
  PCI_SAQ_D_MER_4_0 = 'PCI_SAQ_D_MER_4.0',
  PCI_SAQ_D_SP_4_0 = 'PCI_SAQ_D_SP_4.0',
  PCI_SAQ_P2PE_4_0 = 'PCI_SAQ_P2PE_4.0',
  PCI_SAQ_A_EP_4_0 = 'PCI_SAQ_A_EP_4.0',
  PCI_SAQ_A_4_0 = 'PCI_SAQ_A_4.0',
  PCI_SAQ_B_4_0 = 'PCI_SAQ_B_4.0',
  PCI_SAQ_B_IP_4_0 = 'PCI_SAQ_B_IP_4.0',
  PCI_SAQ_C_4_0 = 'PCI_SAQ_C_4.0',
  PCI_SAQ_C_VT_4_0 = 'PCI_SAQ_C_VT_4.0',
  PCI_SAQ_SPOC_4_0 = 'PCI_SAQ_SPOC_4.0',
}

export enum CMMC_FRAMEWORKS {
  CMMC_LEVEL_1_V2 = 'CMMC_LEVEL_1_V2',
  CMMC_LEVEL_2_V2 = 'CMMC_LEVEL_2_V2',
  CMMC_LEVEL_3_V2_13 = 'CMMC_LEVEL_3_V2.13',
}

export enum HITRUST_SHEET_VAL {
  D1 = 'INFORMATION_PROTECTION_PROGRAM',
  D2 = 'ENDPOINT_PROTECTION',
  D3 = 'PORTABLE_MEDIA_SECURITY',
  D4 = 'MOBILE_DEVICE_SECURITY',
  D5 = 'WIRELESS_SECURITY',
  D6 = 'CONFIGURATION_MANAGEMENT',
  D7 = 'VULNERABILITY_MANAGEMENT',
  D8 = 'NETWORK_PROTECTION',
  D9 = 'TRANSMISSION_PROTECTION',
  D10 = 'PASSWORD_MANAGEMENT',
  D11 = 'ACCESS_CONTROL',
  D12 = 'AUDIT_LOGGING_AND_MONITORING',
  D13 = 'EDUCATION_TRAINING_AND_AWARENESS',
  D14 = 'THIRD_PARTY_ASSURANCE',
  D15 = 'INCIDENT_MANAGEMENT',
  D16 = 'BUSINESS_CONTINUITY_AND_DISASTER_RECOVERY',
  D17 = 'RISK_MANAGEMENT',
  D18 = 'PHYSICAL_AND_ENVIRONMENTAL_SECURITY',
  D19 = 'DATA_PROTECTION_AND_PRIVACY',
}

export const PCI_TOASTER_MESSAGE = `The generation of this report can take up to several minutes. You may continue working in the platform, 
  but please do not close or exit the platform before the report is downloaded.`;

export const BEAZLEY_TOASTER_MESSAGE = `Your file is being downloaded in the background.<br>
  This process can take several minutes.
  You can continue using any part of the
  Centraleyes platform as long as you do not close
  or refresh the browser.`;

// These are the controls whose artifacts are the part of Reports so they are must be in Docx format
export const REPORT_CONTROLS = {
  VENDOR_RISK_HOWDEN_CYBER_360: ['HW-1', 'HW-15', 'HW-43.5'],
  VENDOR_RISK_HOWDEN_RANSOMWARE: ['HW-48.a'],
  VENDOR_RISK_HOWDEN_FULL: ['HW-1', 'HW-15', 'HW-43.5', 'HW-48.a'],
  Beazley: ['ML-1', 'EXP-1', 'RS-49'],
  B_APP_RSWOT: ['ML-3', 'PCC-6', 'PCC-7'],
};

//* Reassessment Confirm All Constants
export const REASSESSMENT_CONFIRM_All = {
  LAMBDA_NAME: 'reassessmentConfirmAll',
  EVENT_TYPE_CONFIRM_ALL: 'CONFIRM_All',
  INFO_MESSAGE: 'Confirming all questions in the background. This may take a couple of seconds.',
};

//* Email Type Constants

export const EntityNotifyTypeEnum = {
  NOTIFY_USER_NEW_QUESTION_ASSIGN: 'NOTIFY_USER_NEW_QUESTION_ASSIGN',
  NOTIFY_USER_REASSESSMENT_STARTED: 'NOTIFY_USER_REASSESSMENT_STARTED',
};

export const NA_SCORES = [999, 998];

export const AVG_TASK_OWNER_TOOLTIP = 'Average overwritten by the remediation task owner';
