<div class="loader" *ngIf="(!isRemediate && !isDataFetched) || !questions.length">
    <div *cygovLoader="(!isRemediate && !isDataFetched) || !questions.length"></div>
</div>

<div *ngFor="let question of questions; let questionIdx = index; let last = last">

    <div class="main-container">
        <div class="question-container-wrapper"
            [ngStyle]="{'width': question?.isVersionClicked ? '34vw':question?.isSeeAllActivity  ? '52vw' :'41.18vw'}">
            <div (click)="toggle(questionIdx)"
                *ngIf="!question?.isSeeAllActivity && (!isRemediate && isDataFetched && !collapsedQuestion[questionIdx]) || (isRemediate && !collapsedQuestion[questionIdx])"
                class="question-container"
                [ngClass]="{'collapsed':!collapsedQuestion[questionIdx]}">
                <div class="header-info lato-16-n-vw">

                    <div class="top-header" *ngIf="questions.length > 1">
                        <!-- <div class="icon" cygovSvgIcon [svgName]="'question-icon'" placement="top"></div> -->
                        <!-- <span class="top-header-type lato-16-b-vw">Question {{questionIdx+1}}</span> -->
                        <div class="arrow-wrapper"
                            [ngClass]="{ expandClass: collapsedQuestion[questionIdx] }">
                            <div class="arrow-icon" cygovSvgIcon [svgName]="'arrow-right-type3'"
                                (click)="collapsedQuestion[questionIdx]=!collapsedQuestion[questionIdx]">
                            </div>
                        </div>
                    </div>
                    <span class="lato-16-l-vw">{{getFrameworkName(question.frameworkName)}} |
                    </span><span class="lato-16-b-vw">{{question.controlName}}</span>
                </div>
                <div class="container-body">
                    <div>
                        <div class="header">
                            <div class="user-list">
                                <div *ngIf="!question?.settings[0]?.isCollaborative && question?.showAverageAvatar"
                                    (click)="showAverageAnswer(question, questionIdx)"
                                    class="circle"
                                    [ngClass]="{'add-border': question?.isAverageSelected}">
                                    <strong class="average-title">AVG</strong>
                                </div>

                                <ng-container *ngFor="let user of assignedUsers[question.id]">
                                    <ng-container *ngIf="user && user.name">
                                        <cygov-username-icon class="cygov-username-icon"
                                            [username]="user ? user?.name : ''"
                                            [height]="(4).toString()"
                                            [bgColor]="!question?.isCollaborative && question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.id && !question?.isAverageSelected? '#29a9eb' : '#262A36'"
                                            [isSelected]="question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.id ? true : false"
                                            (click)="!user.deletedUser && onUsernameClicked(question, questionIdx, user)"
                                            [ngClass]="{'with-pointer': !question.settings.isCollaborative  }"
                                            [greyOutColor]="user?.deletedUser || user?.disable"
                                            [highlightUser]="user?.email === answers[question.id]?.user?.email && answers[question.id].answer && question?.settings?.isCollaborative">
                                        </cygov-username-icon>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngFor="let user of question.assignments">
                                    <ng-container
                                        *ngIf="(user.user && user.user.name) && isNotAlreadyAssigned(question?.id, user?.user?.id)">
                                        <cygov-username-icon class="cygov-username-icon"
                                            [username]="user.user ? user.user?.name : ''"
                                            [height]="(4).toString()"
                                            [bgColor]="!question?.isCollaborative && question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.user?.id && !question?.isAverageSelected? '#29a9eb' : '#262A36'"
                                            [isSelected]="question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.user?.id ? true : false"
                                            (click)="!user.deletedUser && onUsernameClicked(question, questionIdx, user.user)"
                                            [ngClass]="{'with-pointer': !question.settings.isCollaborative  }"
                                            [greyOutColor]="user?.user?.deletedUser || user?.user?.disable"
                                            [highlightUser]="user?.user?.email === answers[question.id]?.user?.email && answers[question.id].answer && question?.settings?.isCollaborative">
                                        </cygov-username-icon>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="question-content" [ngClass]="langDirectionClass">
                            <div class="normal-mode"
                                [ngClass]="{'large-question':question.showQuestionExt}"
                                [ngStyle]="{'position': question.showQuestionExt? 'static' : 'absolute'}"
                                [@openingAnimation]="question.showQuestionExt ? 'expand':'limit'">
                                <span *ngIf="!isRemediate">{{questionIdx+1}}.&nbsp;{{
                                    (question?.name).replaceAll("�",
                                    "'") }}
                                    <span *ngIf="question.showQuestionExt"
                                        class="animation-class">{{question.questionExt}}</span>
                                </span>
                                <span *ngIf="isRemediate">{{questionIdx+1}}.&nbsp;{{
                                    (question?.title).replaceAll("�",
                                    "'") }}
                                    <span *ngIf="question.showQuestionExt"
                                        class="animation-class">{{question.questionExt}}</span>
                                </span>
                                <span
                                    *ngIf="question.showMore && question?.questionExt?.length>0 &&!question.showQuestionExt"
                                    class="label show-cursor flex-end"
                                    (click)="question.showQuestionExt=true">&nbsp;more...
                                </span>
                                <span *ngIf="question.showQuestionExt"
                                    class="label show-cursor flex-end"
                                    (click)="question.showQuestionExt=false">&nbsp;Close...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="question-container"
                *ngIf="collapsedQuestion[questionIdx] && !question?.isSeeAllActivity"
                [ngStyle]="{'padding': question?.isVersionClicked ? '0' : ''}">
                <div class=" header-info lato-16-n-vw">
                    <div class="top-header" *ngIf="questions.length > 1">
                        <!-- <div class="icon" cygovSvgIcon [svgName]="'question-icon'" placement="top"></div> -->
                        <!-- <span class="top-header-type lato-16-b-vw">Question {{questionIdx+1}}</span> -->
                        <div class="arrow-wrapper"
                            [ngClass]="{ expandClass: collapsedQuestion[questionIdx] }">
                            <div class="arrow-icon" cygovSvgIcon [svgName]="'arrow-right-type3'"
                                (click)="toggleCollapse(questionIdx)">
                            </div>
                        </div>
                    </div>
                    <span class="lato-16-l-vw">{{getFrameworkName(question.frameworkName)}} |
                    </span><span class="lato-16-b-vw">{{question.controlName}}</span>
                </div>
                <div class="container-body">
                    <div>
                        <div class="header">
                            <div class="user-list">
                                <div *ngIf="!question?.settings[0]?.isCollaborative && question?.showAverageAvatar"
                                    (click)="showAverageAnswer(question, questionIdx)"
                                    class="circle"
                                    [ngClass]="{'add-border': question?.isAverageSelected}">
                                    <strong class="average-title">AVG</strong>
                                </div>

                                <div *ngIf="smartUser" cygovSvgIcon [svgName]="SMART_MAPPING_SVG"
                                    class="custom-avatars smart-map-avatar bg-on-hover"
                                    [ngStyle]="{'background-color': '#262a36'}">
                                </div>

                                <ng-container *ngFor="let user of question.assignments">
                                    <ng-container
                                        *ngIf="(user.user && user.user.name) && isNotAlreadyAssigned(question?.id, user?.user?.id)">
                                        <div *ngIf="user.user.email && user.user.email === 'integration@integrations.com'; else normalAvatar"
                                            cygovSvgIcon [svgName]="INTEGRATION_SVG"
                                            [ngStyle]="{'background-color': '#262a36'}"
                                            class="custom-avatars integration bg-on-hover">
                                        </div>
                                        <ng-template #normalAvatar>
                                            <cygov-username-icon class="cygov-username-icon"
                                                [username]="user.user ? user.user?.name : ''"
                                                [height]="(4).toString()"
                                                [bgColor]="!question?.isCollaborative && question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.user?.id && !question?.isAverageSelected? '#29a9eb' : '#262A36'"
                                                [isSelected]="question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.user?.id ? true : false"
                                                (click)="!user.deletedUser && onUsernameClicked(question, questionIdx, user.user)"
                                                [ngClass]="{'with-pointer': !question.settings.isCollaborative  }"
                                                [greyOutColor]="user?.user?.deletedUser || user?.user?.disable"
                                                [highlightUser]="user?.user?.email === answers[question.id]?.user?.email && answers[question.id].answer && question?.settings?.isCollaborative"
                                                [currentLoggedInUser]="lastAnswer(question, user , questionIdx )">
                                            </cygov-username-icon>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngFor="let user of assignedUsers[question.id]">
                                    <ng-container *ngIf="user && user.name">
                                        <cygov-username-icon class="cygov-username-icon"
                                            [username]="user ? user?.name : ''"
                                            [height]="(4).toString()"
                                            [bgColor]="!question?.isCollaborative && question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.id && !question?.isAverageSelected? '#29a9eb' : '#262A36'"
                                            [isSelected]="question?.selectedUserAssignment && question?.selectedUserAssignment.id === user?.id ? true : false"
                                            (click)="!user.deletedUser && onUsernameClicked(question, questionIdx, user)"
                                            [ngClass]="{'with-pointer': !question.settings.isCollaborative  }"
                                            [greyOutColor]="user?.deletedUser || user?.disable"
                                            [highlightUser]="user?.email === answers[question.id]?.user?.email && answers[question.id].answer && question?.settings?.isCollaborative">
                                        </cygov-username-icon>
                                    </ng-container>
                                </ng-container>

                            </div>
                            <div class="icons-wrapper">
                                <div *ngIf="hasInfoSecPermission && question.settings[0].isInfoSec && isBnbRisk"
                                    ngbTooltip="InfoSec" placement="left" class="infosec-icon"
                                    cygovSvgIcon [svgName]="'infosec-icon'">
                                </div>
                                <div ngbTooltip="Single" placement="left" class="circle"
                                    [ngClass]="{'add-space': !hasInfoSecPermission}"
                                    *ngIf="hasPermission && !question?.settings[0]?.isCollaborative">
                                </div>
                                <div ngbTooltip="Collaborative" placement="left"
                                    class="collaborative-icon"
                                    [ngClass]="{'add-space': !hasInfoSecPermission || !question.settings[0].isInfoSec}"
                                    *ngIf="hasPermission && question.settings[0].isCollaborative "
                                    cygovSvgIcon [svgName]="'collaboration-circle'">
                                </div>
                            </div>
                        </div>
                        <div class="question-content" [ngClass]="langDirectionClass">
                            <div class="normal-mode"
                                [ngClass]="{'large-question':question.showQuestionExt}"
                                [@openingAnimation]="question.showQuestionExt ? 'expand':'limit'">
                                <span *ngIf="!isRemediate">{{questionIdx+1}}.&nbsp;{{
                                    (question?.name).replaceAll("�",
                                    "'") }}
                                    <span *ngIf="question.showQuestionExt"
                                        class="animation-class">{{question.questionExt}}</span>
                                </span>
                                <span *ngIf="isRemediate">{{questionIdx+1}}.&nbsp;{{
                                    (question?.title).replaceAll("�",
                                    "'") }}
                                    <span *ngIf="question.showQuestionExt"
                                        class="animation-class">{{question.questionExt}}</span>
                                </span>
                                <span
                                    *ngIf="question.showMore && question?.questionExt?.length>0 &&!question.showQuestionExt"
                                    class="label show-cursor flex-end"
                                    (click)="question.showQuestionExt=true">&nbsp;more...
                                </span>
                                <span *ngIf="question.showQuestionExt"
                                    class="label show-cursor flex-end"
                                    (click)="question.showQuestionExt=false">&nbsp;Close...
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="collapsedQuestion[questionIdx]">
                        <div class="answer-list"
                            [ngClass]="{'disabled': question?.isVersionClicked }">
                            <div class="round-checkbox"
                                [ngClass]="{'disabled-question':question.settings[0].isCollaborative || question.isTaskOwner ? false : question?.isAverageSelected || !isUserItSelf}"
                                *ngFor="let answer of question.possibleAnswers; let answerIdx = index">
                                <input
                                    [id]="'label-Q' + question.id + 'A' + answerIdx + 'AS' + question.assessmentId"
                                    type="radio" [name]="question.id + '_' + question.assessmentId"
                                    [disabled]="!(question?.isAverageSelected && question.isTaskOwner && (ENABLED_ANSWERS.includes(answer.toLowerCase()))) 
                                    && (showQuestionWithoutUser || question?.isVersionClicked || question?.isAverageSelected
                                      || (!questions[questionIdx].settings[0].isCollaborative && !isUserItSelf)) "
                                    [value]="answer"
                                    [checked]="isOptionChecked(questionIdx,answer,question)"
                                    (change)="selectionChanged(null, answer, questionIdx)" />
                                <label
                                    [for]="'label-Q' + question.id + 'A' + answerIdx + 'AS' + question.assessmentId"
                                    class="lato-16-n-vw capitalize label answer answer-label">{{
                                    answer.toLowerCase() | capitalizeFirst | replace:'_':' '
                                    }}</label>


                                <div *ngIf="mandatoryTextFunction(answer , question, questionIdx)"
                                    [ngClass]="isPartialSelected[questionIdx] ?  'partial-mandatory-text'  : 'mandatory-comment-text lato-12-n-vw'">
                                    {{mandatoryText}}</div>
                                <div class="slider-input"
                                    [ngClass]="{'disabled-question':question.settings[0].isCollaborative  ? false : question?.isAverageSelected || !isUserItSelf}"
                                    *ngIf="isPartialSelected[questionIdx] && answer.toLowerCase() === 'partial' && !isBeecher">
                                    <cygov-slider [(sliderValue)]="sliderScore[questionIdx]"
                                        [idName]="question.id + 'A'" [leftLabel]="'<25%'"
                                        [rightLabel]="'>90%'" [bluePointer]="true"
                                        [checkPoints]="true"
                                        (sliderValueChange)="selectionChanged(sliderScore[questionIdx], answer, questionIdx)"
                                        [appendThumbVal]="'%'" [minValue]="1" [maxValue]="4">
                                    </cygov-slider>
                                </div>
                            </div>
                        </div>

                        <div [ngClass]="{'hide-element' : question?.isAverageSelected}">
                            <div
                                [ngClass]=" mandatoryTextFunction(null , question, questionIdx, true) ? 'comment-required' :'textarea-container'">
                                <textarea class="lato-16-l-vw explain-answer" rows="1"
                                    [name]="question.id + '_' + question.assessmentId"
                                    [(ngModel)]="questionsNewComment[isRemediate ? question.questionIds[0] : question.id]"
                                    [placeholder]="'Please explain your answer'"
                                    (ngModelChange)="!isRemediate && answers[questionIdx].comment = capitalization && capitalization.transform($event);"
                                    [disabled]="showQuestionWithoutUser || question?.isVersionClicked"></textarea>
                                <button type="button"
                                    class="btn btn-primary text-align no-border-color"
                                    [disabled]="!questionsNewComment[isRemediate ? question.questionIds[0] : question.id] || questionsNewComment[isRemediate ? question.questionIds[0] : question.id].trim() === '' || isCommentInProgress"
                                    (click)="saveComment('create', questionIdx, questionsNewComment[isRemediate ? question.questionIds[0] : question.id],'user')">
                                    ADD
                                </button>
                            </div>
                        </div>

                        <div class="comments" *ngIf="userComments[question.id]?.length">
                            <div class="comment-div"
                                *ngFor="let comment of userComments[question.id];let idxw = index">
                                <div class="comment-section">
                                    <span
                                        class="comment-section-span text-ellipsis">&nbsp;{{getUserName(comment)}}:
                                    </span>
                                    <div class="lato-14-l-vw each-comment">
                                        <div
                                            [ngClass]="{'show-more-text': (!(userComments[question.id][idxw].hasOwnProperty('show')) || userComments[question.id][idxw]?.show ===true) && comment.comment.length >=52}">
                                            &nbsp;{{comment.comment}}
                                        </div>
                                        <ng-template
                                            [ngIf]="(!(userComments[question.id][idxw].hasOwnProperty('show')) || userComments[question.id][idxw]?.show ===true) && comment.comment.length >=52"
                                            [ngIfElse]="closeCase">
                                            <span class="label show-cursor flex-end"
                                                (click)="showMoreLess(question.id,idxw)">&nbsp;more...
                                            </span>
                                        </ng-template>
                                        <ng-template #closeCase>
                                            <span *ngIf="comment.comment.length >=48"
                                                class="label show-cursor flex-end"
                                                (click)="showMoreLess(question.id,idxw)">&nbsp;Close...
                                            </span>
                                        </ng-template>
                                    </div>
                                </div>
                                <span
                                    class="comment-section-span lato-14-n-vw date-div">{{getCommentDate(comment.date)
                                    | date:'long':'UTC'
                                    }}</span>
                            </div>
                        </div>
                        <div *ngIf="isCRB && isHigherRole" class="textarea-container">
                            <textarea class="lato-16-l-vw explain-answer" rows="2"
                                [name]="question.id + '_' + question.assessmentId"
                                [(ngModel)]="questionsAuditComment[isRemediate ? question.questionIds[0] : question.id]"
                                [placeholder]="'Internal Audit Comment'" cygovUserPermission
                                (ngModelChange)="!isRemediate && answers[questionIdx].comment = capitalization && capitalization.transform($event);">
                        </textarea>
                            <div type="button" class="text-align"
                                (click)="saveComment('create', questionIdx, questionsAuditComment[isRemediate ? question.questionIds[0] : question.id],'audit')">
                                ADD</div>
                        </div>
                        <div class="comments"
                            *ngIf="isCRB && isHigherRole && auditComments[question.id]?.length">
                            <div class="comment-div"
                                *ngFor="let comment of auditComments[question.id]">
                                <div class="comment-section">
                                    <span class="comment-section-span">Auditor |
                                        {{getUserName(comment)}}:
                                    </span>
                                    <input class="each-comment" [(ngModel)]="comment.comment"
                                        (focusout)="updateComment(comment.id, comment, question.assessmentId, question)"
                                        [disabled]="currentUser && comment.userId !== currentUser.id" />
                                </div>
                                <span class="comment-section-span ">{{getDate(comment.date)}}</span>
                            </div>
                        </div>

                        <div class="activity">
                            <div class="activities-parent">
                                <div [ngClass]="question.answers?.length  ? 'versions-text' : 'disable'"
                                    (click)="openVersions(question, questionIdx)"
                                    class="versions-text lato-16-n-vw"
                                    *ngIf="!question?.isVersionClicked">
                                    Versions |</div>
                                <div class="lato-14-n-vw see-activity"
                                    (click)="seeAllActivity(question)">See all
                                    activities</div>
                            </div>
                        </div>


                        <div class="footer">
                            <div class="icons-section">
                                <div class="icon download-icon" cygovSvgIcon
                                    [svgName]="'download-exfiles-icon'"
                                    ngbTooltip="Download Existing files" placement="top"
                                    (click)="downloadFile(answers[questionIdx].file,questionIdx)">
                                </div>
                                <span class="counter" *ngIf="getTotalFiles(questionIdx)">
                                    {{getTotalFiles(questionIdx)}}
                                </span>
                                <div *ngIf="!showUploadFileIcon(question)"
                                    [ngClass]="checkIfFile(commentAnswerMapper[question.id][currentSelectedUserId]?.ans?.toLowerCase(),question, questionIdx)   ? 'icon' : 'artifacts-required'"
                                    cygovSvgIcon [svgName]="'upload_collection'" placement="top"
                                    ngbTooltip="Upload files" (click)="uploadArtifact.click()">
                                </div>
                                <input #uploadArtifact class="file-input" type="file"
                                    name="artifact"
                                    accept=".gif, .jpeg, .jpg, .png, .doc, .docx, .csv, .pdf, .xls, .xlsx, .ppt, .txt"
                                    (change)="onFilePicked($event, questionIdx)" multiple />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show-activity" *ngIf="question?.isSeeAllActivity">
                <div class="header">
                    <span class="title lato-16-n-vw">All Activities</span>
                    <div class="versions">
                        <div class="close-row">
                            <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
                                (click)="seeAllActivity(question)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator"></div>
                <div class="commentsAndLogs">
                    <div class="commentsSection">
                        <div class="editComments">
                            <span class="lato-16-n-vw">Comments</span>
                            <div class="inLineOptions">
                                <div [ngClass]="{'disable-icon' : question?.isVersionClicked}"
                                    class="edit-icon"
                                    *ngIf="!question?.isEditComments && userComments[question.id].length>0"
                                    cygovSvgIcon [svgName]="'edit-icon'"
                                    (click)="editComments(question, true)"></div>
                                <div class="save-Button"
                                    *ngIf="question?.isEditComments && (userComments[question.id].length > 0 || question?.isCommentDeleted)">
                                    <button class="btn btn-primary lato-14-n-vw button-tweaks"
                                        (click)="saveResult(question)">SAVE</button>
                                </div>
                                <div class="close-row" *ngIf="question?.isEditComments">
                                    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
                                        (click)="editComments(question, false)">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="userComments[question.id].length > 0 && !question?.showFullComment && !question?.isEditComments"
                            class="comment-list">
                            <div *ngFor="let comment of userComments[question.id];let i=index"
                                class="capitalize every-comment">
                                <div class="comment-div">
                                    <div class="bullet"></div>
                                    <div class="completeComment">
                                        <div class="commentWithName">
                                            <span class="bold lato-14-n-vw"> {{comment.userName}}:
                                            </span>
                                            <div class="each-comment lato-14-b-vw text-white"
                                                *ngIf="!question?.showFullComment && comment.comment">
                                                {{comment.comment?.substring(0, 50) }}
                                                <span *ngIf="comment.comment?.length > 50"
                                                    class="show-more"
                                                    (click)="showMore(question, comment)">
                                                    more...
                                                </span>
                                            </div>
                                        </div>

                                        <span class="display-date lato-14-n-vw">
                                            {{comment.date |
                                            date:'long':'UTC'}}
                                            <span *ngIf="comment?.srcFramework" class="ml-3 source">
                                                source: {{comment.srcFramework}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>

                        <div class="show-full-comment"
                            *ngIf="question?.showFullComment && !question?.isEditComments">
                            <div class="comment-div">
                                <div class="close-row">
                                    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
                                        (click)="showLess(question)">
                                    </div>
                                </div>
                                <span class="lato-14-l-vw capitalize full-comment">
                                    <span class="inner-comment-height comment">
                                        <strong>{{question?.currentComment.userName}}:</strong>
                                        <span
                                            class="add-space comments-style">{{question?.currentComment.comment}}.
                                            <span (click)="showLess(question)"
                                                class="show-less">less</span>
                                        </span>
                                    </span>
                                    <span>{{question?.currentComment.date | date}}</span>
                                </span>

                            </div>
                        </div>

                        <div class="editAllComments" *ngIf="question?.isEditComments">
                            <div class="mainCommentsSection">
                                <div class="deleteCommentRow">
                                    <div *ngFor="let comment of userComments[question.id];let i=index"
                                        class="lato-14-l-vw every-comment">
                                        <textarea type="text" #inputTag class="each-comment"
                                            rows="2" (focusin)="focusing(true,$event)"
                                            (focusout)="focusing(false,$event); addCommentIds(question, comment.id, $event)">{{comment.comment}}</textarea>
                                        <br />
                                        <div class="close-row">
                                            <div class="close-icon" cygovSvgIcon
                                                [svgName]="'x-icon-type2'"
                                                (click)="deleteComments(question, i)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="vertical-separator"></div>
                    <div class="logsSection">
                        <span class="lato-16-n-vw">Logs</span>
                        <div class="all-logs">
                            <div class="logs" *ngFor="let log of question?.questionLogs">
                                <span class="point"></span>
                                <div class="each-log">
                                    <span class="lato-14-l-vw"> {{log.message}}</span>
                                    <span class="lato-14-l-vw"> {{log.createdAt |
                                        date:'long':'UTC'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="scroll-section" *ngIf="question?.questionLogs?.length">
                            <div class="margin-left-minus-2px"
                                [ngClass]="question?.disableUpArrow ? 'up-arrow-disabled' : 'up-arrow'"
                                (click)="moveScrollLog(question, arrowsEnum.UP)" cygovSvgIcon
                                [svgName]="'arrow-up-type1'">
                            </div>
                            <div [ngClass]="question?.disableDownArrow ? 'down-arrow-disabled' : 'down-arrow'"
                                (click)="moveScrollLog(question, arrowsEnum.DOWN)" cygovSvgIcon
                                [svgName]="'arrow-down-type1'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="vertical-separator" *ngIf="question?.isVersionClicked"></div> -->
        <div class="version-container" *ngIf="question?.isVersionClicked">
            <div class="header-versions">
                <div class="show-versions">
                    <div class="lato-16-b-vw"> Versions</div>
                    <div class="close-row">
                        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
                            (click)="openVersions(question, questionIdx)">
                        </div>
                    </div>
                </div>
                <div class="versions-body">
                    <div class="every-version" *ngFor="let version of question?.questionVersions">
                        <div [ngClass]="version.selected ? 'vertical' : 'add-space'"></div>
                        <span class="point"
                            [ngStyle]="{'background-color': version.selected ? '#29a9eb':'#c5c9d1'}"></span>
                        <div class="lato-14-b-vw version-click"
                            (click)="selectVersion(question, questionIdx, version)"
                            [ngStyle]="{color: version.selected ? '#29a9eb':'#c5c9d1'}">
                            {{version.date | date : 'd.MM.yy'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
<!-- <div class="question-separator">
</div> -->

<div class="footer-container">
    <div class="d-flex btns-container">
        <cygov-button class="button-margin" [buttonText]=" 'SAVE'" [buttonWidth]="'5vw'"
            [buttonColor]="'#29a9eb'" [hoverColor]="'#0F92E3'"
            *ngIf="(!isRemediate && isDataFetched) || (isRemediate && questions && questions.length > 0)"
            (click)="onSave()" [disableClick]="saving">
        </cygov-button>

        <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#1A2336'"
            [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5vw'" [textColor]="'#fff'"
            *ngIf="(!isRemediate && isDataFetched) || (isRemediate && questions && questions.length > 0)"
            (click)="this.activeModal.close()">
        </cygov-button>
    </div>
</div>

<!-- <div class="btn-wrapper"
    *ngIf="(!isRemediate && isDataFetched) || (isRemediate && questions && questions.length > 0)">
    <button (click)="onSave()" class="lato-18-n-vw capitalize btn btn-submit" [disabled]="saving">
        Save
    </button>
</div> -->