export const Services = {
  TENABLE: 'tenableInformation',
  RAPID7: 'rapid7Information',
  CROWDSTRIKE: 'crowdstrikeInformation',
  AZUREDEFENDER: 'azuredefenderInformation',
  MICROSOFTDEFENDER: 'microsoftdefenderInformation',
};
export const ServiceName = {
  TENABLE: 'tenable',
  RAPID7: 'rapid7',
  CROWDSTRIKE: 'crowdstrike',
  AZUREDEFENDER: 'azuredefender',
  MICROSOFTDEFENDER: 'microsoftdefender',
};
export enum Common {
  MPL = 'MPL',
}

export const Defaults = {
  IntegrationEmail: 'integration@integrations.com',
  SmartUserEmail: 'smartmapping@smartmapping.com',
};

export const Logo = {
  TENABLE: 'tenable_logo',
};

export const AZURE_FRAMEWORK = {
  NIST_SP_800_53_R5: 'NIST SP 800 53 R5',
};

export const EXEMPTED_CYBER_ARK_FRAMEWORKS = ['CAIQ_V1', 'CAIQ_2025'];
