export class RiskRegisteryEnum {
  static Ignore = 'Ignore';
  static Accept = 'Accept';
  static Normal = 'Normal';
  static Remediate = 'Remediate';
  static REMEDIATION_TASKS = 'Remediation Tasks';
  static CONTROLS = 'Control List';
}

export enum ReportDownloadOptionsEnum {
  DOC = 'docx',
  PDF = 'pdf',
  CSV = 'csv',
  XLS = 'xls',
}

export enum RiskTableObjEnum {
  RISK_NAME_JOIN = 'riskTitle',
  RISK_ID = 'idTitle',
  SUB_ENTITY = 'subEntity',
  RISK_NAME = 'risk-name',
  STATUS = 'riskStatus',
  PRIORITY = 'riskPriority',
  INHERENT = 'inherentRisk',
  FINANCIAL = 'primaryLoss',
  RESIDUAL = 'residualRisk',
  COST_REMEDIATE = 'costToRemediate',
  CONTROLS = 'controlIds',
  IMPACT = 'riskImpact',
  FINANCIAL_IMPACT = 'financialImpact',
  TASKS = 'riskTasks',
  TAGGING = 'riskTags',
  RELATED_ASSETS = 'relatedAssets',
  RESIDUAL_FINANCIAL = 'Financial',
  FINANCIAL_RESIDUAL = 'financialResidual',
}

export enum RiskReportEnum {
  idTitle = 'ID',
  riskTitle = 'Name',
  inherentRisk = 'Inherent',
  ResidualRisk = 'Residual Risk',
  subEntity = 'Entity',
  riskStatus = 'Status',
}

export enum RiskTypeEnum {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
  HARD_MANUAL = 'hard_manual',
  AI = 'AI',
}

export enum RiskTypeMapper {
  manual = 'Autonomous',
  automatic = 'Automatic',
  hard_manual = 'Manual',
  ai = 'AI',
}

export enum RiskTypeEnumMapper {
  Autonomous = 'manual',
  Automatic = 'automatic',
  Manual = 'hard_manual',
  AI = 'AI',
}

export enum RiskFilterCheckEnum {
  RISK_OWNER = 'riskOwner',
  VIEW = 'view',
  RESIDUAL = 'residual',
  INHERENT = 'inherent',
  TAGS = 'tags',
  GROUPS = 'groups',
  TOTAL = 'Total',
  FINANCIAL = 'financial',
  FINANCIAL_WITH = 'With',
  FINANCIAL_WITHOUT = 'Without',
  SOURCE = 'source',
  ENTITIES = 'entities',
}

export enum RiskFilterEnums {
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
  'entities' = 'Entity',
  'tags' = 'Tag',
  'groups' = 'Group',
  'inherentImpact' = 'Inherent | Impact',
  'inherentProbability' = 'Inherent | Probability',
  'residualImpact' = 'Residual | Impact',
  'residualProbability' = 'Residual | Probability',
  'riskOwner' = 'Risk Owner',
  'financial' = 'Financial',
  'source' = 'Source',
  'status' = 'Status',
}
