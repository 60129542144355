<div *ngIf="loading" class="loader-height">
    <div *cygovLoader="loading"></div>
</div>
<div class="modal-body" *ngIf="!loading">
    <!------------------------------ Header Section -------------------------------->
    <div class="modal-header" *ngIf="!isFromGeneralSettings">
        <div class="capitalize lato-16-n-vw text-white">SETTINGS</div>
    </div>
    <!------------------------------ Body Content Section -------------------------------->
    <div class="modal-main" *cygovLoader="loading">
        <!------------------------------ Assessment & Additional Requirement Section -------------------------------->
        <div class="assessment-section">
            <div class="assessment-header">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
                    <li [ngbNavItem]="1" [ngClass]="{'active-link':activeTabId===1}">
                        <a ngbNavLink class="pointertab">Assessment</a>
                        <ng-template ngbNavContent>
                            <!------------------------------------------------------------------------------------>
                            <!-----------------------------------Assessment AREA ---------------------------------->
                            <!------------------------------------------------------------------------------------>
                            <div class="content-body"
                                [ngClass]="{'content-body-informatica' : isInformatica}">
                                <cygov-framework-frequency-section
                                    class="assessment-section informatica-assessment-section"
                                    [assessmentData]="assessmentData" [entity]="entity"
                                    [isFromDefaultSettings]="true"
                                    (assessmentDataChange)="assessmentDataChange($event)">

                                </cygov-framework-frequency-section>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" [ngClass]="{'active-link':activeTabId===2}">
                        <a ngbNavLink class="pointertab"> Additional
                            Requirements</a>
                        <ng-template ngbNavContent>
                            <!------------------------------------------------------------------------------------>
                            <!-------------------------------------Additional Requirements------------------------>
                            <!------------------------------------------------------------------------------------>
                            <div class="content-body">
                                <cygov-require-certif-section class="requirements-section"
                                    [additionalData]="additionalData" [isDefaultSetting]="true"
                                    (additionalDataChange)="updateCertifications($event)"
                                    [customDefaultTypes]="customDefaultTypes">
                                </cygov-require-certif-section>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <!-- <div [class]="'custom-active-border tab-' + activeTabId"></div> -->
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
        <!------------------------------ Separation Line -------------------------------->
        <div class="horizontal-underline"></div>
        <!------------------------------ Email Notification Section -------------------------------->
        <div class="automated-email-section">
            <!------------------------------ Notification Header Section -------------------------------->
            <div class="email-header">
                Automated Email Messages
            </div>
            <!------------------------------ Notification Body Section -------------------------------->
            <div class="email-messages-body">
                <ng-container *ngFor="let notification of NotifyMessages,let i=index">
                    <div class="message-row"
                        [ngClass]="{'message-row-assessment' : i ===1 || i ===2 }">
                        <!------------------------------ Notification Toggle button -------------------------------->
                        <div class="togglebtnContainer">
                            <cygov-toggle-button [isChecked]="notification.shouldNotify"
                                (changed)="notification.shouldNotify = $event" [id]="i.toString()">
                            </cygov-toggle-button>
                        </div>
                        <!------------------------------ Notification Text/description -------------------------------->
                        <div class="toggleText">
                            <div class="action-icon">
                                <span class="email-icon" cygovSvgIcon
                                    tooltipClass="customized-tooltip"
                                    [ngbTooltip]="'Edit email template'" container="body"
                                    [svgName]="'envelope-icon'"></span>
                            </div>
                            <span class="value"
                                [ngClass]="{'notification-text' : notification.allowCustomDay}">{{
                                notification.description }}</span>
                            <span *ngIf="i === 1 || i === 2">
                                <span cygovSvgIcon [svgName]="'info-board-icon'"
                                    class="info-icon-automated-messages"
                                    [ngbTooltip]="i === 1 
        ? 'Please note A reminder will be sent to vendors for incomplete assessment on each of the following days before the deadline.' 
        : 'Please note a reminder will be sent to vendors for incomplete assessment on each of the following days after the deadline.'"
                                    [placement]="'right'" tooltipClass="tooltip-automated-messages">
                                </span>

                            </span>
                            <!------------------------------ Notification Frequency Section -------------------------------->
                            <div class="add-icon-container" *ngIf=" notification.allowCustomDay">
                                <div (click)="addCustomNotifyDay(notification,i)"
                                    class='add-icon txt-white abs-centre'>+</div>

                            </div>
                            <div class="frequency">
                                <div class="frequency-container"
                                    [ngClass]="{'frequency-container-assessment' : i === 1 || i ===2}">
                                    <ng-container
                                        *ngFor="let item of notification.fixedDays; let j=index">
                                        <div class="checkbox-with-number-input checkbox-with-counter"
                                            *ngIf="item.custom">
                                            <div class="selection-box"
                                                (click)="item.selected=!item.selected">
                                                <div *ngIf="item.selected" cygovSvgIcon
                                                    class="right-tick" [svgName]="'right-tick'">
                                                </div>
                                            </div>
                                            <ng-container *ngTemplateOutlet="daysCounter; context: { 
                                                label: notification.id, 
                                                customDays: notification.fixedDays,
                                                index: j 
                                                }">
                                            </ng-container>
                                            &nbsp; <span class="lato-14-n-vw text-white">days</span>

                                            <div class="cross-icon-area"
                                                *ngIf="removeFunctionalityCheck(notification)"
                                                (click)="removeCustomDay(notification,item.id)">
                                                <div class="cross-icon">
                                                    <div class="cross-alpha">
                                                        X
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="checkbox-with-name" *ngIf="!item.custom">
                                            <div class="selection-box"
                                                (click)="item.selected=!item.selected">
                                                <div *ngIf="item.selected" cygovSvgIcon
                                                    class="right-tick" [svgName]="'right-tick'">
                                                </div>
                                            </div>
                                            <span class="check-box-text lato-14-n-vw text-white">
                                                {{item.value }} {{item.value > 1 ? 'days' : 'day'}}
                                            </span>
                                            <div class="cross-icon-area-simple"
                                                *ngIf="removeFunctionalityCheck(notification)"
                                                (click)="removeCustomDay(notification,item.id)">
                                                <div class="cross-icon">
                                                    <div class="cross-alpha">
                                                        X
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </ng-container>

                                </div>
                            </div>


                            <!------------------------------ Notification Slider -------------------------------->
                            <div *ngIf="notification?.riskSlider" class="risk-slider">
                                <cygov-slider [moveableThumbLabel]="true"
                                    [(sliderValue)]="notification.riskScore" [leftLabel]="'1'"
                                    [rightLabel]="'10'" [minValue]="1" [maxValue]="10"
                                    [gradient]="riskGradient">
                                </cygov-slider>
                            </div>

                            <!-- Radio Buttons options  -->
                            <div *ngIf="notification?.isFindings" class="radio-buttons">
                                <div class="round-checkbox"
                                    *ngFor="let opt of notification.findings ">
                                    <input [id]="'label-' + opt.label" type="radio"
                                        [name]="'label-' + opt.label" [value]="opt.label"
                                        [checked]="opt.selected" #surveyRadio
                                        (change)=" opt.selected" class="jarl-radio" />
                                    <label [for]="'label-' + opt.label"
                                        class="lato-14-n-vw label target text-white">{{opt.label.replace('-','
                                        ')}}</label>
                                </div>
                            </div>


                        </div>
                        <div class="seprator-full-width"
                            [ngStyle]="{'top' : i === NotifyMessages.length - 1 ? '9vh' : i === 1 || i === 2  ? '31vh' : '7vh' }">
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>
        <!------------------------------ Separation Line -------------------------------->
        <!-- <div class="horizontal-underline"></div> -->
        <!------------------------------ General Section -------------------------------->
        <div class="automated-email-section general-setting-section">
            <div class="email-header">
                General
            </div>
            <!-- <div class="horizontal-underline"></div> -->
            <div class="email-messages-body">
                <div class="message-row">
                    <div class="toggleText">
                        <span class="value text-white">4th Party weighting on external risk
                            score</span>
                        <div class="slider-input slider-area">
                            <cygov-slider [moveableThumbLabel]="true" [moveableSignValue]="'%'"
                                [(sliderValue)]="sliderScore" [leftLabel]="'10%'"
                                [rightLabel]="'50%'" [minValue]="10" [maxValue]="50"
                                [gradient]="gradient">
                            </cygov-slider>
                        </div>
                    </div>
                </div>

            </div>
            <div class="vendor-count-container">
                <div class="vendor-text lato-16-n-vw text-white">Number of Vendors:</div>
                <div class="vendor-count"> <span class="lato-16-n-vw">{{
                        entity?.vendors?.totalVendorsCreated }}</span> / <span
                        class="lato-16-n-vw">{{ entity?.vendors?.vendorsTotalCount}}</span></div>
            </div>
            <div class="add-more-vendor-btn">
                <button class="add-more lato-16-n-vw"
                    (click)="openVendorPackageModal(vendorPackageHeader, vendorPackageContent, vendorPackageFooter)">ADD
                    MORE</button>
            </div>

        </div>
        <!------------------------------ Footer Section to Save functionality -------------------------------->
        <div class="modal-footer">
            <div class="footer-action-btns">
                <cygov-button class="button-margin" [buttonText]="'SAVE'" [buttonWidth]="'6.42vw'"
                    [buttonHeight]="'3vh'" [textSize]="'16'" (click)="saveDefaultSetting()">
                </cygov-button>
            </div>
        </div>
    </div>

</div>

<ng-template #daysCounter let-label="label" let-customDays="customDays" let-index="index">
    <div class="counter">
        <!-- Decrement Button -->
        <div class="incrementor-decrementor-wrapper" (click)="decrement(label, customDays, index)"
            [ngClass]="{
          'decremental-disabled': customDays ? customDays[index]?.value <= 1 : vendorsCount === 50
        }">
            <div class="decrementor" cygovSvgIcon [svgName]="'arrow-down-type1'"></div>
        </div>

        <!-- Counter Input -->
        <input class="risk-editable-input lato-12-n-vw" [disabled]="true"
            [value]="label !== 'VENDORS_COUNT' ? customDays[index]?.value : vendorsCount"
            type="number" min="1" />

        <!-- Increment Button -->
        <div class="incrementor-decrementor-wrapper" (click)="increment(label, customDays, index)"
            [ngClass]="{
          'incremental-disabled': 
            (label === 'notify2' && customDays[index]?.value >= 30) || 
            (label === 'notify3' && customDays[index]?.value >= 7)
        }">
            <div class="incrementor" cygovSvgIcon [svgName]="'arrow-up-type1'"></div>
        </div>
    </div>
</ng-template>


<ng-template #vendorPackageHeader>
    <!-- Empty for filters -->
    <span class="lato-24-n-vw text-white"></span>
</ng-template>

<ng-template #vendorPackageContent>
    <div
        class='d-flex justify-content-center align-items-center flex-column confirm-filter-popup-content'>
        <span class='lato-20-n-vw'>I would like to add the following vendor package:
        </span>
        <div class="vendor-package-counter-container">
            <ng-container
                *ngTemplateOutlet="daysCounter; context: { label: 'VENDORS_COUNT', count: 50, customDays: 0, }">
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #vendorPackageFooter>
    <div class='atack-surface-footer d-flex justify-content-center'>
        <cygov-button class='standard-btn lato-14-n-vw' [buttonText]="'REQUEST'"
            [buttonWidth]="'5.2vw'" [textSize]="'14'" [buttonColor]="'#29A9EB'"
            [hoverColor]="'#0B76B9'"
            (click)="openVendorPackageAcknowledgementModal(vendorPackageAcknowledgementHeader, vendorPackageAcknowledgementContent, vendorPackageAcknowledgementFooter); sendData()">
        </cygov-button>
        <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#030715'"
            [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5.2vw'" [textColor]="'#C5C9D1'"
            (click)="closeModalTemplate()">
        </cygov-button>
    </div>
</ng-template>


<ng-template #vendorPackageAcknowledgementHeader>
    <!-- Empty for filters -->
</ng-template>

<ng-template #vendorPackageAcknowledgementContent>
    <div
        class='d-flex justify-content-center align-items-center flex-column confirm-filter-popup-content'>
        <div class="tick-icon-container">
            <div class="tick-icon" cygovSvgIcon [svgName]="'complete-popup'"></div>
        </div>
        <span class='lato-22-n-vw text-white'>Your request has been received.
        </span>
        <span class='lato-22-n-vw text-white'>A Centraleyes team member will
            contact you shortly.</span>
    </div>
</ng-template>

<ng-template #vendorPackageAcknowledgementFooter>
    <div class='external-scan-request-acknowledgement-footer d-flex justify-content-center'>
        <cygov-button class='lato-14-n-vw' [buttonText]="'OK'" [buttonWidth]="'6.6vw'"
            [textSize]="'14'" [buttonColor]="'#29A9EB'" [hoverColor]="'#0B76B9'"
            [buttonHeight]="'2.963vh'" (click)="closeModalTemplate()"></cygov-button>

    </div>
</ng-template>